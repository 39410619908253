.signup-main-container-inner {
  display: flex;
  min-height: 90vh;
}

.signup-left {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 100px;
  gap: 70px;
}

.signup-right {
  width: 50%;
  border-radius: 30px 0px 0px 30px;
  background: var(--blue-off-3, #f8f8ff);
  padding-top: 100px;
  padding-left: 61px;
  padding-right: 61px;
  /* position: relative; */
}
.signup-right h3 {
  color: #000;
  font-family: Roboto;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 30px;
}

.steps {
  max-width: 384px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 30px;
}
.step {
  width: 24px;
  height: 24px;
  background-color: rgba(84, 89, 255, 0.4);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.line {
  height: 2px;
  background-color: rgba(84, 89, 255, 0.4);
  flex-grow: 1;
  position: relative;
}
.line::before {
  content: "";
  height: 7px;
  width: 7px;
  border-radius: 50%;
  background-color: rgba(84, 89, 255, 0.4);
  position: absolute;
  top: -2px;
  left: -2px;
}
.line::after {
  content: "";
  height: 7px;
  width: 7px;
  border-radius: 50%;
  background-color: rgba(84, 89, 255, 0.4);
  position: absolute;
  top: -2px;
  right: -2px;
}
.step.active,
.line.active {
  background-color: #3e0ffe;
}

.line.active::after,
.line.active::before {
  background-color: #3e0ffe;
}

.signup-forms-input {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.input-group {
  flex: 1 1 200px;
  /* margin-bottom: 20px; */
  display: flex;
  flex-direction: column;
  gap: 7px;
}
.signup-form{
  padding-bottom: 20px;
}
.signup-form label {
  color: #666;
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 10px;
}
.input-group .input input {
  border-radius: 8px;
  background: var(--White, #fff);
  color: #666;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  padding-top: 13px;
  padding-bottom: 13px;
  padding-left: 50px;
  width: 100%;
}
.input-group .input {
  position: relative;
}
.input-group .input img {
  position: absolute;
  top: 10px;
  left: 15px;
  z-index: 1;
}

.signup-forms-input select {
  width: 100%;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 15px;
  padding-bottom: 15px;
  color: #666;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  border-right: 10px solid #fff;
  border-radius: 10px;
  position: relative;
  z-index: 1;
}
.signup-forms-input select option {
  color: #666;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
}

.submit-btn-outer {
  margin-top: 15px;
  display: flex;
  justify-content: center;
}

.submit-btn-outer .btn-outline {
  color: var(--Primary, #3e0ffe);
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
  border-radius: 18.5px;
  border: 1px solid var(--Primary, #3e0ffe);
  background: var(--White, #fff);
  padding: 7px 45px;
  cursor: pointer;
}
.submit-btn-outer.step2 {
  margin-top: 10px;
  display: block;
}

.input-group.check label {
  color: var(--Grey-3, #656263);
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
  transform: translateY(5px);
}

.input-group.check label span {
  color: #3e0ffe;
}
.input-group.terms-check {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-direction: row;
}
.step3-info {
  color: #000;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
  margin-top: 10px;
}

.check-icon {
  width: 17px;
}
.signup-right .res-image {
  display: flex;
  justify-content: center;
  align-items: center;
}
.signup-right .res-image img {
  display: none;
}
.input-group.terms-check label {
  color: var(--Grey-3, #656263);
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
  transform: translateY(4px);
}
.input-group.terms-check label span {
  color: #3e0ffe;
}
.error-step3 {
  font-family: Roboto;
  font-size: 12px;
  color: #e90000;
}

.global-password-constraints{
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5px;
  height: 50px;
}
.global-password-constraints p{
  white-space: nowrap;
  font-family: Roboto, "San-serif";
  font-size: 11px;
  color: #666;
}
.signup-back-btn{
  cursor: pointer;
}
.password-view-icons{
  position: absolute;
  top: 13px;
  left: 15px;
}

.business-type .global-dropdown{
  z-index: 1000;
}

.location-dropdown-global .global-dropdown{
  z-index: 1;
}
@media (max-width: 430px) {
  .signup-left {
    display: none;
    width: 0;
  }
  .signup-right {
    width: 100%;
    background-color: #fff;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 80px;
  }
  .steps {
    /* width: 100%; */
    max-width: 250px;
  }
  .step {
    width: 16px;
    height: 16px;
  }
  .check-icon {
    width: 13px;
  }
  .signup-right .res-image img {
    display: block;
    margin-bottom: 40px;
  }
  .input-group .input input {
    background: var(--Grey-4, #f4f6f8);
  }
  .input-group .input input#phone {
    width: 100%;
  }
  .signup-forms-input .input-group {
    margin-bottom: 10px;
  }
}

.signup-success-popup {
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 500px;
}
.signup-success-popup h3 {
  color: #333;
  text-align: center;
  font-family: Roboto;
  font-size: 20px;
  font-weight: 400;
}
