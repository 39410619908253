.features-inner {
  padding: 128px 80px 70px 80px;
}

.features-heading {
  margin-bottom: 49px;
}

.features-paragraph {
  max-width: 849px;
  margin: 0 auto;
  margin-bottom: 60px;
}

.features-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
}
.features-card {
  padding: 10px 10px 12px 10px;
  border-radius: 8px;
  border: 1px solid var(--Blue-off-0, rgba(84, 89, 255, 0.4));
  background: var(--White, #fff);
  width: 410px;
  /* flex-shrink: 0; */
  flex: 1 1 330px;
}

.features-card:nth-child(2) {
  max-height: 189px;
}
.features-card:nth-child(3) {
  max-height: 175px;
}
.features-card:nth-child(4) {
  max-height: 174px;
}
.features-card:nth-child(5) {
  height: 235px;
  transform: translateY(-55px);
}
.features-card:nth-child(6) {
  height: 254px;
  transform: translateY(-70px);
}

.image-appoinment {
  display: flex;
  max-width: 390px;
  border-radius: 5px;
  border: 0.5px solid #ddd;
  background: var(--blue-off-1, rgba(84, 89, 255, 0.1));
  padding: 14px 0px 14px 15px;
  align-items: center;
  gap: 10px;
}

.image-appoinment p {
  color: #000;
  font-feature-settings: "clig" off, "liga" off;

  /* H5 SB 16 */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.list-items {
  padding-left: 30px;
  margin-top: 15px;
}
.list-items li {
  color: rgba(0, 0, 0, 0.8);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 191%; /* 30.56px */
  letter-spacing: -0.176px;
}

.list-diplay {
  max-width: 350px;
  display: flex;
  justify-content: space-between;
}

@media (min-width: 1043px) and (max-width: 1200px){
  .features-card:nth-child(2) {
    max-height: 220px;
  }
  .features-card:nth-child(3) {
    max-height: 205px;
  }
  .features-card:nth-child(4) {
    max-height: 174px;
  }
  .features-card:nth-child(5) {
    height: 235px;
    transform: translateY(-30px);
  }
  .features-card:nth-child(6) {
    height: 254px;
    transform: translateY(-45px);
  }
}

@media (min-width: 1000px) and (max-width: 1201px){
  .features-inner {
    padding: 128px 20px 70px 20px;
  }
  .features-card {
    padding: 10px 10px 12px 10px;
    border-radius: 8px;
    border: 1px solid var(--Blue-off-0, rgba(84, 89, 255, 0.4));
    background: var(--White, #fff);
    width: 410px;
    /* flex-shrink: 0; */
    flex: 1 1 280px;
  }
  .features-card:nth-child(2) {
    max-height: 220px;
  }
  .features-card:nth-child(3) {
    max-height: 205px;
  }
  .features-card:nth-child(4) {
    max-height: 174px;
  }
  .features-card:nth-child(5) {
    height: 235px;
    /* transform: translateY(-55px); */
  }
  .features-card:nth-child(6) {
    height: 254px;
    /* transform: translateY(-70px); */
  }
}

@media (max-width: 430px) {
  .features-inner {
    padding: 110px 20px 40px 20px;
  }

  .features-heading {
    margin-bottom: 20px;
    font-weight: 500;
  }

  .features-paragraph {
    margin-bottom: 30px;
  }

  .features-cards {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 25px;
  }

  .features-card {
    max-width: 315px;
  }

  .features-card:nth-child(2) {
    max-height: 189px;
  }
  .features-card:nth-child(3) {
    max-height: 220px;
  }
  .features-card:nth-child(4) {
    max-height: 180px;
  }

  .features-card:nth-child(5) {
    height: 220px;
    transform: translateY(0px);
  }
  .features-card:nth-child(6) {
    height: 220px;
    transform: translateY(0px);
  }

  .list-items {
    padding-left: 30px;
    margin-top: 15px;
    padding-right: 20px;
  }
  .list-items li {
    font-weight: 400;
  }
}
