
.Professional-about-page {
    width: 100%;
    
  }
  
  .Prof-about-main {
    width: 100%;
    position: relative;
    height: 70vh;
    border-radius: 0px 0px 30px 30px;
  }
  
  .Prof-about-main img {
    width: 100%;
    height: 100%;
    border-radius: 0px 0px 30px 30px;
  }
  
  .pro-about-detail {
    /* background-color: #00000061; */
    /* height: 100%;
    width: 100%;
    position: absolute;
    border-bottom-left-radius: 30px; */
    position: absolute;
    top: 0px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    border-bottom-right-radius: 30px;
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  
    
  }
  
  /* .pro-about-detail .heading-para {
    position: absolute;
    width: 50%;
    top: 160px;
    left: 100px;
  } */
  .pro-about-detail .heading-para input {
    color: #fff;
    font-feature-settings: "clig" off, "liga" off;
    margin-bottom: 12px;
    font-family: Roboto;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 60px; /* 125% */
    max-width: 700px;
    background: transparent;
    display: block;
  }

  .professional-heading-para-head-edit{
    position: absolute;
    left: -15px;
    top: 0px;
  }
  .professional-heading-para-para-edit{
    position: absolute;
    left: -20px;
    top: 60px;
  }
  
  .pro-about-detail .heading-para h1 span {
    color: #fff;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Roboto;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 60px;
  }
  
  .pro-about-detail .heading-para textarea {
    color: #fff;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 127.188%; /* 22.894px */
    max-width: 700px;
    background: transparent;
    border: none;
    resize: none;
    width: 700px;
    height: 100px;
  }
  
  .Pro-About-second {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 100px auto;
    text-align: center;
    gap: 30px;
    max-width: 1440px;
    margin: 50px auto;
  }
  
  .Pro-About-second input {
    color: #000;
    font-family: Roboto;
    font-size: 34px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 64.706% */
    background: transparent;
  }
  .Pro-About-second textarea {
    width: 85%;
    color: #666;
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    background: transparent;
    resize: none;
    border: none;
    height: 100px;
    width: 900px;
  }
  
  .About-Pro-third {
    width: 100%;
    position: relative;
    height: 577px;
    margin-bottom: 85px;
  }
  
  .Pro-about-fimg1 {
    width: 100%;
    position: absolute;
    top: 150px;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .Pro-about-fimg1 img {
    width: 100%;
  }
  
  .Pro-about-fimg2 {
    width: 100%;
    position: absolute;
    bottom: 55px;
    left: 0;
    right: 0;
  }
  .Pro-about-fimg2 img {
    width: 100%;
  }
  
  .Pro-about-thirdimg {
  display: flex;
  align-items: center;
  justify-content: center;
  }
  .professional-team-image-container{
    width: 1099px;
    height: 576px;
  }
  .professional-team-image-container img {
    
    width: 100%;
    height: 100%;
    border-radius: 20px;
  }
  
  .about-page-global-overlay{
    /* border: 2px solid red; */
    border-radius: 0px 0px 30px 30px;
  }
  
  
  
  
  @media (max-width: 430px) {
    .Prof-about-main {
      width: 100%;
      padding: 0px 0;
      max-height: 65vh;
    }
  
    .Prof-about-main img {
      width: 100%;
      margin: 0 auto;
    }
  
    .pro-about-detail {
      background-color: transparent;
    }
  
    .pro-about-detail .heading-para {
      width: 100%;
      padding: 0 20px;
      margin: 0 auto;
      padding-top: 60px;
    }
    .pro-about-detail .heading-para input {
      /* color: #000; */
      text-align: center;
      font-feature-settings: "clig" off, "liga" off;
      font-family: Roboto;
      font-size: 34px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      width: 100%;
    }
    .pro-about-detail .heading-para textarea {
      color: #FFF;
      text-align: center;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-top: 20px;
      max-width: 320px;
    }

    .professional-heading-para-head-edit{
      position: absolute;
      left: 40px;
      top: 60px;
    }
    .professional-heading-para-para-edit{
      position: absolute;
      left: 15px;
      top: 130px;
    }
  
    .pro-para-hidden {
      display: none;
    }
  
    .Pro-About-second {
      margin: 30px 0 30px 0;
    }
  
    .Pro-About-second input {
      color: #000;
      font-family: Roboto;
      font-size: 30px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 64.706% */
      max-width: 330px;
    }
  
    .Pro-About-second textarea {
      color: #666;
      text-align: center;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      max-width: 330px;
    }
  
    .Pro-para2-hidden {
      display: none;
    }
  
    .About-Pro-third {
      width: 100%;
      max-height: 300px;
      margin-bottom: 20px;
    }
  
    .Pro-about-fimg1 {
      max-width: 735.015px;
      height: 55px;
      flex-shrink: 0;
      position: relative;
      top: 95px;
      left: 0;
      right: 0;
      right: 0;
      display: none;
    }
  
    .Pro-about-fimg2 {
      position: relative;
      top: 20px;
      left: 0;
      right: 0;
      right: 0;
      max-width: 652.851px;
      height: 55px;
      /* transform: rotate(10.507deg); */
      flex-shrink: 0;
      display: none;
    }
    .Pro-about-thirdimg {
      max-height: 250px;
      max-width: 350px;
      flex-shrink: 0;
      position: static;
      left: 0px;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 30px;
      margin: 0 20px;
    }
    
    .Pro-about-thirdimg img {
      width: 100%;
      height: 235px;
    }

    .professional-team-image-container{
      width: 100%;
      height: 100%;
    }
  
  }
  