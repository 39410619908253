.add-referral-popup{
    width: 500px;
    padding: 20px;
}
.add-referral-popup h1{
    font-family: Roboto, 'sans-serif';
    font-size: 28px;
    margin-bottom: 30px;
    color: #303030;
}
.del-referral-popup {
    width: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    padding-bottom: 20px;
  }
  

@media(max-width:430px){
    .add-referral-popup{
        width: 90%;
    }
    .del-referral-popup {
        width: 90%;
      }
}