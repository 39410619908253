.standard-home-overlay {
  width: 100%;
  height: 100%;
  background: rgba(211, 211, 211, 0.2);
}
.standard-home-section {
  height: 100%;
}
.standard-home-content {
  max-width: 760px;
  margin: 0px auto;
  padding: 200px 20px 115px 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
}

.standard-home-heading {
  color: var(--White, #fff);
  text-align: center;
  font-family: Roboto;
  font-size: 48px;
  font-weight: 600;
  line-height: 60px;
}

.standard-home-heading-edit {
  position: absolute;
  right: 10px;
  top: 5px;
}
.standard-home-para-edit {
  position: absolute;
  right: 10px;
  top: 100px;
}

.standard-home-para {
  max-width: 680px;
  color: var(--White, #fff);
  text-align: center;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 400;
  line-height: 127.188%;
}

.standard-reviews-head {
  color: var(--Dark, #25233c);
  font-family: Roboto;
  font-size: 34px;
  font-weight: 500;
  text-align: center;
  margin: 60px 0 20px 0;
}

/* review */
.standard-home-review {
  max-width: 930px;
  padding: 45px 55px 36px 55px;
  border-radius: 10px;
  background: var(--White, #fff);
  box-shadow: 0px 15px 40px 0px rgba(114, 114, 114, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  position: relative;
}

img.standard-home-review-comas {
  position: absolute;
  top: -35px;
  left: 0;
}

.standard-home-review-para {
  color: #666;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
}
/* slider */

.standard-reviews-slider {
  width: 100%;
  height: 120%;
  display: flex;
  align-items: center;
  position: relative;
}

.standard-reviews-slider-inner .swiper {
  /* max-width: 100%; */
  /* border: 1px solid red; */
  padding: 50px 40px;
  /* margin: 0 100px; */
  overflow-y: visible;
  overflow-x: hidden;
  position: static;
}

.standard-reviews-slider
  .standard-reviews-slider-inner
  .swiper-button-next {
  position: absolute;
  right: 25px;
}

.standard-reviews-slider
  .standard-reviews-slider-inner
  .swiper-button-prev {
  position: absolute;
  left: 15px;
}

.standard-reviews-slider .swiper-button-next,
.standard-reviews-slider .swiper-button-prev {
  background: transparent;
  border-radius: 50%;
  border: 2px solid #757575;
  /* background: #3e0ffe; */
  font-size: 10px;
  /* padding: 10px; */
  width: 40px;
  height: 40px;
  /* margin-right: -100px; */
}

/* .standard-reviews-slider .swiper-button-next:hover, .standard-reviews-slider .swiper-button-prev:hover{
    background: #757575;
} */

.standard-reviews-slider-inner {
  max-width: 1020px;
  margin: 0 auto;
  /* border: 1px solid green; */
  overflow: hidden;
  position: relative;
}

.standard-reviews-slider .swiper-button-prev:after {
  font-size: 18px;
  stroke-width: 3px;
}

.standard-reviews-slider .swiper-button-next:after {
  stroke-width: 3px;
  font-size: 18px;
}

.standard-reviews-slider .swiper-button-next:hover:after,
.standard-reviews-slider .swiper-button-prev:hover:after {
  color: #fff;
}
.standard-home-section {
  background-color: rgba(0, 0, 0, 0.5);
}

.standard-home-content textarea,
.standard-home-para {
  background: transparent;
  resize: none;
  border: none;
}
.standard-template-main-banner-top {
  height: 70vh;
  position: relative;
}
.standard-template-main-banner-top img {
  width: 100%;
  height: 100%;
}
.standard-template-main-banner-content {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}
.standard-home-para {
  width: 670px;
  height: 100px;
}
.standard-home-content textarea {
  width: 700px;
}

@media (max-width: 431px) {
  /* .standard-home-overlay {
    width: 100%;
    height: 100%;
    background: rgba(211, 211, 211, 0.2);
  } */
  .standard-home-section {
    height: 100%;
  }
  .standard-home-content {
    max-width: 760px;
    margin: 0px auto;
    padding: 0;
    display: block;
    padding-left: 20px;
  }
  .standard-home-content2 {
    max-width: 300px;
    padding: 150px 0px 20px 0px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
  }

  .standard-home-heading {
    text-align: left;
    font-size: 24px;
    line-height: normal;
    max-width: 283px;
  }

  .standard-home-heading-edit {
    position: absolute;
    right: 10px;
    top: 150px;
  }
  .standard-home-para-edit {
    position: absolute;
    right: 10px;
    top: 220px;
  }

  .standard-home-para {
    max-width: 242px;
    text-align: left;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 127.188%;
  }

  .standard-reviews-head {
    color: var(--Dark, #25233c);
    font-family: Roboto;
    font-size: 34px;
    font-weight: 500;
    text-align: center;
    margin: 60px 0 20px 0;
  }

  /* review */
  .standard-home-review {
    max-width: 390px;
    padding: 25px 32px 22px 32px;
    gap: 10px;
  }

  img.standard-home-review-comas {
    position: absolute;
    top: 20px;
    left: 17px;
    width: 20px;
    height: 17px;
  }

  .standard-home-review-para {
    font-size: 12px;
    line-height: 22px;
    /* border: 1px solid red; */
    max-width: 326px; /* Set your specific width */
    height: 154px; /* 1.5em is the line height, 7 is the number of lines */
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 7; /* Limit to 7 lines */
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
  }
  /* slider */

  .standard-reviews-slider {
    width: 100%;
    height: 120%;
    display: flex;
    align-items: center;
    position: relative;
  }

  .standard-reviews-slider-inner .swiper {
    /* max-width: 100%; */
    /* border: 1px solid red; */
    padding: 50px 40px;
    /* margin: 0 100px; */
    overflow-y: visible;
    overflow-x: hidden;
    position: static;
  }

  .standard-reviews-slider .standard-reviews-slider-inner .swiper-button-next {
    position: absolute;
    right: 20px;
  }

  .standard-reviews-slider .standard-reviews-slider-inner .swiper-button-prev {
    position: absolute;
    left: 20px;
  }

  .standard-reviews-slider .swiper-button-next,
  .standard-reviews-slider .swiper-button-prev {
    background: transparent;
    padding: 10px;
    border-radius: 50%;
    border: 2px solid #757575;
    /* background: #3e0ffe; */
    font-size: 10px;
    /* padding: 10px; */
    width: 32px;
    height: 32px;
    /* margin-right: -100px; */
  }

  /* .standard-reviews-slider .swiper-button-next:hover, .standard-reviews-slider .swiper-button-prev:hover{
      background: #757575;
  } */

  .standard-reviews-slider-inner {
    max-width: 1020px;
    margin: 0 auto;
    /* border: 1px solid green; */
    overflow: hidden;
    position: relative;
  }

  .standard-reviews-slider-inner .swiper-button-prev::after,
  .standard-reviews-slider-inner .swiper-button-next::after {
    stroke-width: 3px;
    font-size: 15px;
  }

  .standard-reviews-slider .swiper .swiper-button-next:hover:after,
  .standard-reviews-slider .swiper .swiper-button-prev:hover:after {
    color: #fff;
  }
  .standard-home-section {
    background-color: rgba(0, 0, 0, 0.5);
  }

  .standard-home-content textarea,
  .standard-home-para {
    background: transparent;
    resize: none;
    border: none;
  }
  .standard-template-main-banner-top {
    height: 60vh;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .standard-template-main-banner-top img {
    width: 100%;
    height: 100%;
  }
  .standard-template-main-banner-content {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }
  /* .standard-home-content{
    padding: 0 20px;
  } */
  .standard-home-para {
    width: 670px;
    height: 100px;
  }
  .standard-home-content textarea {
    width: 700px;
  }
}

@media (max-width: 400px) {
  .standard-home-review-para {
    height: 134px;
    display: -webkit-box;
    -webkit-line-clamp: 6;
  }
}
