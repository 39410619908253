.add-team-member-inputs{
    display: flex;
    flex-wrap: wrap;
    max-width: 1100px;
    gap: 15px;
}

.add-team-member-input{
    flex: 1 1 300px;
}
.add-team-member-input{
    max-width: 340px;
}

.working-time-open-close{
    position: relative;
    margin-bottom: 40px;
}
.open-close-time{
    position: relative;
}
.fill-previous-btn{
    position: absolute;
    top: -30px;
    right: 0;
    background-color: #4F48FF;
    padding: 3px 10px;
    border-radius: 3px;
    font-family: Roboto, sans-serif;
    color: #fff;
    font-size: 11px;
}

.select-job-title-input{
    position: relative;
}

.select-job-title-input .fill-previous-btn{
    top: -5px;
}

@media (max-width: 430px) {
    .add-team-member-input{
        max-width: 100%;
    }
    
}