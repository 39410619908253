.first-section-p {
    width: 100%;
    position: relative;
    height: 70vh;
  }
  
  .first-section-p img {
    width: 100%;
    filter: grayscale(50%);
  }
  
  .first-section-overlay {
    display: flex;
    z-index: 1;
    align-items: center;
    padding: 0 50px;
  }
  .first-section-p .first-section-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  
  .Slider-side {
    width: 50%;
    height: 33%;
    display: flex;
    align-items: flex-end;
    position: relative;
    margin: 0 auto;
    /* border: 1px solid green; */
    overflow: visible;
  }
  
  .pro-slider-sider-slider {
    position: relative;
    width: 100%;
    /* border: 1px solid white; */
    display: flex;
    justify-content: center;
  }
  
  .Slider-side .Slider-sideinner {
    /* border: 1px solid yellow; */
    width: 300px;
    overflow: visible;
    /* margin: 0 0 0 5rem; */
  }
  
  .Slider-side .Slider-sideinner .swiper {
    max-width: 300px;
    /* border: 1px solid red; */
    overflow: hidden;
    position: static;
  }
  
  .Slider-side .Slider-sideinner .swiper .swiper-button-next {
    position: absolute;
    right: 400px;
  }
  
  .Slider-side .Slider-sideinner .swiper .swiper-button-prev {
    position: absolute;
    left: 20px;
  }
  
  .Slider-side .Slider-sideinner .swiper .swiper-button-next,
  .Slider-side .Slider-sideinner .swiper .swiper-button-prev {
    width: 1rem;
    height: 1rem;
    padding: 19px;
    fill: #150b0b;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));
  }
  
  .Slider-side .Slider-sideinner .swiper .swiper-button-prev:after {
    font-size: 15px;
    stroke-width: 3px;
    stroke: #fff;
    color: #fff;
  }
  
  .Slider-side .Slider-sideinner .swiper .swiper-button-next:after {
    stroke-width: 3px;
    stroke: #fff;
    color: #fff;
    font-size: 15px;
  }
  
  .upper-slider-prev {
    position: absolute;
    left: -35px;
  }
  .upper-slider-next,
  .upper-slider-prev {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .text-side {
    display: flex;
    width: 50%;
    gap: 1rem;
    flex-direction: column;
    align-items: flex-end;
    color: #fff;
    /* padding: 6rem 5rem 0 0; */
    position: absolute;
    top: 120px;
    right: 80px;
  }
  
  .text-side .professional-heading {
    color: var(--White, #fff);
    text-align: right;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Roboto;
    font-size: 3rem;
    font-style: normal;
    font-weight: 600;
    line-height: 3.75rem;
    margin-bottom: 20px;
    background: transparent;
    resize: none;
    border: none;
  }

  .professional-heading-edit{
    position: absolute;
    top: 0px;
    right: -10px;
  }
  .professional-sub-heading-edit{
    position: absolute;
    top: 140px;
    right: 0px;
  }
  
  .text-side textarea.professional-sub-heading {
    width: 25.5rem;
    color: #bbb;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Roboto;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 127.188%;
    margin-bottom: 20px;
    resize: none;
    border: none;
    background:transparent ;
    height: 100px;
  }
  
  .text-side button {
    border-radius: 1.15625rem;
    background: #d8aa5d;
    display: inline-flex;
    padding: 0.4375rem 2.8125rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    color: var(--White, #fff);
    font-family: Roboto;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375rem; /* 183.333% */
    /* margin: 5px auto; */
  }
  
  .reviews-slider-prev {
    position: absolute;
    left: 45px;
  }
  
  .reviews-slider-next {
    position: absolute;
    right: 45px;
  }
  
  .reviews-slider-prev,
  .reviews-slider-next {
    width: 56px;
    height: 56px;
    border-radius: 50%;
  }
  
  .section-two-p {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
  }
  
  .section-two-p .para {
    max-width: 53.875rem;
    padding: 0 20px;
    color: #666;
    text-align: center;
    font-feature-settings: "clig" off, "liga" off;
    /* Text Regular 16 */
    font-family: Roboto;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: none;
    resize: none;
    background: transparent;
    width: 890px;
    height: 40px;
  }
  /* Carusal  */
  
  .Home-slider-p {
    width: 100%;
    display: flex;
    align-items: center;
    margin: 10px 0 30px 0;
    /* border: 1px solid black; */
    padding: 0 100px 10px 100px;
    position: relative;
    font-family: Roboto;
  }
  
  .home-slider-inner-p .swiper {
    /* max-width: 100%; */
    height: 10%;
    /* border: 1px solid red; */
    /* padding: 0px 10px; */
    padding: 0 10px 50px 10px;
    /* margin: 0 auto; */
    overflow: hidden;
    position: static;
  }
  
  .second-slider-outer {
    height: 330px;
    padding: 50px 0;
  }
  
  .card-p {
    overflow: visible;
    max-width: 465px;
    font-size: 18px;
    background: #fff;
    margin: 0px 10px 10px 10px;
    border-radius: 10px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.15);
  }
  
  .card-p .image-rating-p img {
    z-index: 999;
  }
  
  .home-slider-inner-p .swiper-slider .card-p p {
    color: #666;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 408px;
  }
  
  .Home-slider-p .home-slider-inner-p .swiper .swiper-button-next {
    position: absolute;
    right: 100px;
  }
  
  .Home-slider-p .home-slider-inner-p .swiper .swiper-button-prev {
    position: absolute;
    left: 100px;
    border: 1px solid red;
  }
  
  .Home-slider-p .swiper-button-next,
  .Home-slider-p .swiper-button-prev {
    background-color: white;
    background-color: rgba(255, 255, 255, 0.5);
    right: 10px;
    padding: 30px;
    color: #000;
    fill: black;
    stroke: black;
    border-radius: 350px;
    background: #1c0c11;
    font-size: 10px;
    width: 56px;
    height: 56px;
    /* margin-right: -100px; */
  }
  
  .home-slider-inner-p {
    max-width: 960px;
    margin: 0 auto;
    /* border: 1px solid green; */
    overflow: hidden;
  }
  
  .Home-slider-p .swiper .swiper-button-prev:after {
    font-size: 20px;
    stroke-width: 3px;
    stroke: #fff;
    color: #fff;
  }
  
  .Home-slider-p .swiper .swiper-button-next:after {
    stroke-width: 3px;
    stroke: #fff;
    color: #fff;
    font-size: 20px;
  }
  
  .image-rating-p {
    display: flex;
    flex-direction: column;
    gap: 5px;
    /* margin-top: -70px; */
    z-index: 999;
  }
  
  .image-rating-p img {
    width: 4rem;
    height: 4rem;
    flex-shrink: 0;
    z-index: 999;
  }
  
  .image-rating-p .ratings h3 {
    color: #25233c;
    font-feature-settings: "clig" off, "liga" off;
  
    /* H5 SB 16 */
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  
  .ratings-p img {
    width: 57px;
    height: 9px;
  }
  .professional-template-wave{
    position: absolute;
    left: 0;
    right: 0;
    height: 150px;
    bottom: 0;
  }
  
  @media (max-width: 431px) {
    .first-section-p {
      width: 100%;
      position: relative;
      height: 450px;
      /* border: 1px solid red; */
      margin-bottom: 230px;
      /* padding-top: 100px; */
    }
    
  
    /* .first-section-p img {
      width: 100%;
      filter: grayscale(50%);
      height: 242px;
    } */
  
    .first-section-overlay {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      z-index: 1;
      align-items: center;
      padding: 0 20px;
    }
    .first-section-p .first-section-overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  
    .Slider-side {
      width: 100%;
      height: 33%;
      display: flex;
      align-items: flex-end;
      position: absolute;
      margin: 0 auto;
      /* border: 1px solid green; */
      overflow: visible;
      top: 440px;
      /* border: 1px solid red; */
      padding: 0 20px;
    }
  
    .Slider-side .swiper-slide {
      width: 145px;
      width: 195px;
      /* border: 1px solid red; */
    }
  
    .Slider-side .swiper-slide img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  
    .Slider-side .Slider-sideinner {
      /* border: 1px solid yellow; */
      max-width: 300px;
      overflow: visible;
      /* border: 1px solid red; */
      margin: 0 auto;
      /* margin: 0 0 0 5rem; */
    }
  
    .Slider-side .Slider-sideinner .swiper {
      max-width: 300px;
      /* border: 1px solid red; */
      overflow: hidden;
      position: static;
    }
  
    .Slider-side .Slider-sideinner .swiper .swiper-button-next {
      position: absolute;
      right: 20px;
    }
  
    .Slider-side .Slider-sideinner .swiper .swiper-button-prev {
      position: absolute;
      left: 20px;
    }
  
    .Slider-side .Slider-sideinner .swiper .swiper-button-next,
    .Slider-side .Slider-sideinner .swiper .swiper-button-prev {
      width: 1rem;
      height: 1rem;
      padding: 19px;
      fill: #150b0b;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));
    }
  
    .Slider-side .Slider-sideinner .swiper .swiper-button-prev:after {
      font-size: 15px;
      stroke-width: 3px;
      stroke: #fff;
      color: #fff;
    }
  
    .Slider-side .Slider-sideinner .swiper .swiper-button-next:after {
      stroke-width: 3px;
      stroke: #fff;
      color: #fff;
      font-size: 15px;
    }
  
    .upper-slider-prev {
      position: absolute;
      left: 10px;
    }
    .upper-slider-next {
      position: absolute;
      right: 10px;
    }
    .upper-slider-next,
    .upper-slider-prev {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  
    .upper-slider-previous-chevron,
    .upper-slider-next-chevron {
      /* size: 10px; */
      width: 17px;
      height: 17px;
      /* stroke: white; */
    }
    /* .upper-slider-next-chevron{
      border: 1px solid red;
      size: 10px;
      width: 17px;
      height: 17px;
      color: white;
    } */
    .text-side {
      display: flex;
      width: 100%;
      gap: 8px;
      flex-direction: column;
      align-items: center;
      color: #fff;
      position: absolute;
      top: 20px;
      right: 0px;
      left: 0;
      padding-top: 130px;
    }
  
    .text-side .professional-heading {
      color: var(--White, #fff);
      text-align: center;
      font-feature-settings: "clig" off, "liga" off;
      font-family: Roboto;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-bottom: 0px;
      max-width: 320px;
    }

    .professional-heading-edit{
      top: 130px;
      right: 60px;
    }

    .professional-sub-heading-edit{
      top: 190px;
      right: 50px;
    }
  
    .text-side textarea.professional-sub-heading {
      max-width: 285px;
      text-align: center;
      /* border: 1px solid red; */
      color: #bbb;
      font-feature-settings: "clig" off, "liga" off;
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 12px;
      margin-bottom: 20px;
      height: 50px;
      /* border: 1px solid red; */
    }
  
    .text-side button {
      border-radius: 1.15625rem;
      background: #d8aa5d;
      display: inline-flex;
      padding: 0.4375rem 2.8125rem;
      justify-content: center;
      align-items: center;
      gap: 0.625rem;
      color: var(--White, #fff);
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      /* margin: 5px auto; */
    }
  
    .reviews-slider-prev {
      position: absolute;
      left: 10px;
    }
  
    .reviews-slider-next {
      position: absolute;
      right: 10px;
    }
  
    .reviews-slider-prev,
    .reviews-slider-next {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  
    .review-slider-previous-chevron,
    .review-slider-next-chevron {
      width: 15px;
      height: 15px;
    }
  
    /* section 2 */
  
    .section-two-p {
      width: 100%;
      display: flex;
      gap: 10px;
      flex-direction: column;
      align-items: center;
    }
  
    .review {
      margin-top: 0;
      font-family: Roboto;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  
    .section-two-p .para {
      font-size: 16px;
      max-width: 340px;
      height: 78px;
    }
    /* Carusal  */
  
    .Home-slider-p {
      width: 100%;
      display: flex;
      align-items: center;
      margin: 10px 0 30px 0;
      /* border: 1px solid black; */
      padding: 0 20px 10px 20px;
      position: relative;
  
    }
  
    .home-slider-inner-p .swiper {
      height: 100%;
      padding: 0 10px 0px 10px;
      /* margin: 0 auto; */
      overflow: hidden;
      position: static;
    }
  
    .second-slider-outer {
      height: 100%;
      padding: 50px 0px 20px 0px;
    }
  
    .card-p {
      overflow: visible;
      max-width: 465px;
      font-size: 18px;
      background: #fff;
      margin: 0px 10px 10px 10px;
      border-radius: 10px;
      padding: 30px 20px 20px 20px;
      display: flex;
      flex-direction: column;
      box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.15);
      /* border: 1px solid red; */
    }
  
    .card-p .image-rating-p img {
      z-index: 999;
    }
  
    .home-slider-inner-p .card-p p {
      font-size: 12px;
      font-weight: 400;
      line-height: 15px;
      max-width: 408px;
      /* border: 1px solid red; */
      max-height: 80px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 5; 
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
    }
  
    .Home-slider-p .home-slider-inner-p .swiper .swiper-button-next {
      position: absolute;
      right: 100px;
    }
  
    .Home-slider-p .home-slider-inner-p .swiper .swiper-button-prev {
      position: absolute;
      left: 100px;
      border: 1px solid red;
    }
  
    .Home-slider-p .swiper-button-next,
    .Home-slider-p .swiper-button-prev {
      background-color: white;
      background-color: rgba(255, 255, 255, 0.5);
      right: 10px;
      padding: 10px;
      color: #000;
      fill: black;
      stroke: black;
      border-radius: 350px;
      background: #1c0c11;
      /* font-size: 10px; */
      width: 32px;
      height: 32px;
      /* margin-right: -100px; */
    }
  
    .home-slider-inner-p {
      max-width: 960px;
      margin: 0 auto;
      /* border: 1px solid green; */
      overflow: hidden;
    }
  
    .Home-slider-p .swiper .swiper-button-prev::after {
      font-size: 12px !important;
      stroke-width: 3px;
      stroke: #fff;
      color: #fff;
    }
  
    .Home-slider-p .swiper .swiper-button-next::after {
      stroke-width: 3px;
      stroke: #fff;
      color: #fff;
      font-size: 12px;
    }
  
    .image-rating-p {
      display: flex;
      flex-direction: column;
      gap: 5px;
      /* margin-top: -70px; */
      z-index: 999;
    }
  
    .image-rating-p img {
      width: 4rem;
      height: 4rem;
      flex-shrink: 0;
      z-index: 999;
    }
  
    .image-rating-p .ratings h3 {
      font-weight: 400;
      font-size: 14px;
    }
  
    .ratings-p img {
      width: 50px;
      height: 9px;
    }
  
    .professional-template-wave{
      height: 120px;
    }
  }
  