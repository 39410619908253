.mt-20 {
  margin-top: 20px;
}
.mt-30 {
  margin-top: 30px;
}
.website-design-head {
  color: #000;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 10px;
}

.website-design-span {
  color: #666;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.website-templates {
  margin: 30px 0;
  max-width: 1080px;
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  align-items: center;
  gap: 30px;
}

.website-template {
  max-width: 338px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  border-radius: 20px;
  border: 1px solid var(--Blue-off-0, rgba(84, 89, 255, 0.4));
  background: var(--blue-off-3, #f8f8ff);
  cursor: pointer;
  flex: 1 1 200px;
}

.inner-website-design {
  border-radius: 20px;
  background: var(--White, #fff);
  /* width: 308px; */
  padding: 0 23.5px;
  width: 100%;
  height: 200px;
}
.inner-website-design img {
  width: 100%;
  height: 100%;
}
.web-design-input-label {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.web-design-input-label input {
  width: 24px;
  height: 24px;
  accent-color: #3e0ffe;
}

.web-design-input-label input,
.web-design-input-label label {
  cursor: pointer;
}
.web-design-input-label label {
  color: #000;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
}

.colors-pallet-section {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 30px;
  margin-top: 15px;
}

.left-colors-pallet {
  display: flex;
  align-items: center;
  gap: 5px;
  position: relative;
}

.left-colors-pallet input {
  appearance: none;
  -webkit-appearance: none;
  width: 36px;
  height: 36px;
  border-radius: 5px;
  border: 2px solid #eeeeff;
  position: relative;
  cursor: pointer;
}

.left-colors-pallet input::before {
  content: "";
  position: absolute;
  left: 3px;
  top: 2px;
  width: 27px;
  height: 28px;
  border-radius: 5px;
  /* background: #30E702; */
}
.left-colors-pallet input#green-color::before {
  background: #30e702;
}
.left-colors-pallet input#red-color::before {
  background: #f60505;
}
.left-colors-pallet input#black-color::before {
  background: #656263;
}
.left-colors-pallet input#dark-blue-color::before {
  background: #3c3963;
}
.left-colors-pallet input#gray-color::before {
  background: #cdcdcd;
}
.left-colors-pallet input#purple-color::before {
  background: #aea7ff;
}

.left-colors-pallet input:checked {
  border: 2px solid #5459ff;
}

.right-colors-pallet {
  display: flex;
  align-items: center;
  gap: 10px;
}

.right-colors-pallet label {
  color: #666;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}

.right-colors-pallet input {
  width: 83px;
  padding: 10px;
  border: 2px solid #cdcdcd;
  border-radius: 5px;
  color: var(--Grey-5, #cdcdcd);
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.home-page-section {
  margin: 30px 0;
}

#width100 {
  max-width: 100%;
}

/* ------------------- */

.upload-banner-image-section {
  display: flex;
  align-items: center;
  gap: 15px;
  position: relative;
  width: 100%;
}

.home-page-section-heading {
  color: #000;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
}

.upload-banner-image-section input.upload-content-image {
  display: none;
}

.upload-banner-image-section label {
  cursor: pointer;
  width: 100%;
}

.upload-banner-img {
  width: 100%;
  height: 460px;
  /* overflow: hidden; */
  border-radius: 8px;
  /* border: 1px dashed var(--Grey-2, #3c3963); */
  background: var(--blue-off-3, #f8f8ff);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  object-fit: cover;
}

.upload-banner-img img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  object-fit: cover;
}

.banner-img-error-message {
  color: red;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  max-width: 1080px;
  padding-top: 10px;
}

.upload-banner-photo {
  border-radius: 8px;
  background: #d9d9d9;
  width: 100%;
  height: 460px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.up-img {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;
}

/* .up-img img {
  width: 32px;
  height: 33px;
} */

.banner-img-drag-drop {
  max-width: 234px;
  padding: 10px;
  position: absolute;
  top: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--White, #fff);
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 127.188%;
  border: 1px dashed var(--White, #fff);
}

/* #banner-drag-drop {
  border: 1px dashed var(--Grey-3, #656263);
  color: var(--Grey-3, #656263);
} */

.select-services {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}
/* 
.business-service {
  position: relative;
  width: 95px;
  height: 34px;
  border-radius: 30px;
  background: var(--Grey-4, #f4f6f8);
} */

.business-service input {
  appearance: none;
  width: 100%;
  height: 100%;
  border-radius: 30px;
  /* background: transparent; */
  position: absolute;
  z-index: -1;
  background: var(--Grey-4, #f4f6f8);
}

.business-service,
.business-service-label {
  position: relative;
  width: 95px;
  height: 34px;
  border-radius: 30px;
  /* background: var(--Grey-4, #F4F6F8); */
  background: transparent;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--Grey-3, #656263);
  text-align: center;
  font-family: Roboto;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 18.5px;
  cursor: pointer;
}

.business-service input:checked + .business-service-label {
  background: var(
    --Gradient,
    linear-gradient(4deg, #3e0ffe 3.77%, #5459ff 59.6%)
  );
  color: var(--White, #fff);
}

/* .checked{
  background: var(
    --Gradient,
    linear-gradient(4deg, #3e0ffe 3.77%, #5459ff 59.6%)
  );
  color: var(--White, #fff);
} */

.business-hours-checkbox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

#width-118 {
  max-width: 95px;
  height: 34px;
}
#width-118 input {
  color: #666;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 183.333% */
}

.working-timings {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 15px;
  align-items: center;
}

.open-close-time {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  /* justify-content: space-between; */
  gap: 15px;
}

.working-time-open-close {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
}

.open-time-label-input,
.close-time-label-input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.open-time-label-input .edit-client-icon-input,
.close-time-label-input .edit-client-icon-input {
  margin-bottom: 0;
}

.open-time-label-input label,
.close-time-label-input label {
  color: #000;
  text-align: center;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.website-design-services {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}
.website-design-service {
  border-radius: 30px;
  background: var(--Grey-4, #f4f6f8);
  color: var(--Grey-3, #656263);
  font-family: Roboto;
  font-size: 10px;
  font-weight: 400;
  line-height: 18.5px;
  flex: 1 1 80px;
  max-width: 97px;
  padding: 10px 0px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.website-design-service-active {
  background: var(
    --Gradient,
    linear-gradient(4deg, #3e0ffe 3.77%, #5459ff 59.6%)
  );
  color: #fff;
}

.hex-code-display {
  width: 30px;
  height: 30px;
  border-radius: 5px;
  background: #fff;
}
.website-design-date-error {
  position: absolute;
  left: 10px;
  bottom: -15px;
}

.accept-payment-text{
  font-size: 15px;
  font-family: Roboto;
}
.accept-payment-text button{
  background: transparent;
  color: #3e0ffe;
}
@media (max-width: 1420px) {
  .website-templates {
    max-width: 100%;
    /* border: 1px solid red; */
  }
}
@media (max-width: 1300px) {
  .website-templates {
    gap: 15px;
  }

  .inner-website-design {
    padding: 0 15px;
  }
  .web-design-input-label label {
    font-size: 14px;
  }
  .web-design-input-label input {
    width: 18px;
    height: 18px;
  }
}
@media (max-width: 1200px) {
  .inner-website-design {
    height: 180px;
  }
}

@media(max-width:431px){
  .open-close-time {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 15px;
  }
}