.voucher-code {
  color: #000;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  margin-top: 10px;
}

.send-voucher-popup {
  width: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 20px;
}

.send-voucher-inputs {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
  max-width: 1100px;
}
.send-voucher-input {
  flex: 1 1 270px;
  max-width: 290px;
}

.preview-voucher-popup {
  width: 712px;
  height: 459px;
  position: relative;
  /* border: 1px solid red; */
  margin: 0 20px;
}
.preview-voucher-popup img {
  position: absolute;
  right: 0;
  bottom: 0;
}
.voucher-preview-popup-inner {
  padding: 56px 35px;
}
.voucher-preview-popup-inner h1 {
  text-align: center;
  -webkit-text-stroke-width: 1;
  -webkit-text-stroke-color: #f9b067;
  font-family: "Send Flowers";
  font-size: 64px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background: linear-gradient(4deg, #f9b067 3.77%, #ffde8f 59.6%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.voucher-preview-popup-inner h3 {
  color: var(--blue-off-4, #7d7eaa);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 127.188%;
  margin-bottom: 50px;
}

.preview-voucher-top-bar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.preview-voucher-top-bar-inner {
  width: 149px;
  height: 6px;
  border-radius: 0px 0px 13.5px 13.5px;
  background: var(
    --Gradient,
    linear-gradient(4deg, #3e0ffe 3.77%, #5459ff 59.6%)
  );
  margin: 0 auto;
}

.preview-voucher-bottom-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.preview-voucher-bottom-bar-inner {
  width: 149px;
  height: 6px;
  border-radius: 13.5px 13.5px 0px 0px;
  background: var(
    --Gradient,
    linear-gradient(4deg, #3e0ffe 3.77%, #5459ff 59.6%)
  );
  margin: 0 auto;
}
.preview-voucher-popup span {
  color: var(--blue-off-4, #7d7eaa);
  text-align: center;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 25px;
}

.preview-voucher-details {
  max-width: 260px;
}
.preview-voucher-details p:first-child {
  color: #666;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
.preview-voucher-details p:last-child {
  color: #000;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.preview-voucher-close {
  position: absolute;
  top: 16px;
  right: 14px;
  cursor: pointer;
}
@media (max-width: 430px) {
  .send-voucher-input {
    max-width: 100%;
  }
  .preview-voucher-popup {
    max-width: 712px;
    height: 300px;
    position: relative;
    /* border: 1px solid red; */
    margin: 0 20px;
  }
  .preview-voucher-popup img {
    display: none;
  }
  .voucher-preview-popup-inner {
    padding: 20px 15px;
    /* border: 2px solid red; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

  }
  .voucher-preview-popup-inner h1 {
    text-align: center;
    -webkit-text-stroke-width: 1;
    -webkit-text-stroke-color: #f9b067;
    font-family: "Send Flowers";
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    background: linear-gradient(4deg, #f9b067 3.77%, #ffde8f 59.6%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .voucher-preview-popup-inner h3 {
    color: var(--blue-off-4, #7d7eaa);
    text-align: center;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 127.188%;
    margin-bottom: 20px;
  }
  
  .preview-voucher-top-bar-inner {
    width: 149px;
    height: 5px;
  }
  
  .preview-voucher-bottom-bar-inner {
    width: 149px;
    height: 5px;
  }
  
  .preview-voucher-details {
    width: 100%;
  }
  .preview-voucher-details p:first-child {
    color: #666;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
  .preview-voucher-details p:last-child {
    color: #000;
    font-family: Roboto;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .preview-voucher-close {
    position: absolute;
    top: 16px;
    right: 14px;
    cursor: pointer;
  }
}
