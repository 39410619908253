.website-appointment-form-inputs {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 20px;
}

.website-appointment-main-container{
  padding: 0 20px;
  padding-bottom: 30px;
  max-width: 1240px;
  margin: 0 auto;
}

.website-appointment-main-container h1 {
  color: #000;
  font-family: Roboto;
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 40px;
  margin-top: 40px;
}

@media(max-width: 951px){
  .website-appointment-form-inputs {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 20px;
  }
  
}
@media(max-width: 691px){
  .website-appointment-form-inputs {
    grid-template-columns: repeat(2, 1fr);
  }
  
}
@media(max-width: 431px){
  .website-appointment-form-inputs {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 10px;
  }

  .website-appointment-main-container h1 {
    color: #000;
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 20px;
    margin-top: 0px;
  }
  
}