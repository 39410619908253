.appoinments-container {
  padding: 0 30px;
  padding-left: 20px;
}

.appoinments-container span.appoinment-view {
  color: #666;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
}

.appoinments-calender-view-outer {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}

.appoinments-badge {
  flex: 1 1 160px;
  padding: 15px;
  border-radius: 8px;
  background: var(--White, #fff);
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.15);
  max-width: 160px;
}
.appoinments-badge-top {
  margin-bottom: 26px;
}
.appoinments-badge-top span {
  color: #666;
  text-align: right;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
}

.appoinments-badge-btn {
  width: 61px;
  border-radius: 5px;
  font-family: Roboto;
  font-size: 10px;
  font-weight: 400;
  padding: 0 5px;
}
.appoinments-badge-new {
  background: rgba(165, 239, 255, 0.4);
  color: #0073c7;
}
.appoinments-badge-cancelled {
  background: #ffe9e0;
  color: #ff332d;
}
.appoinments-badge-completed {
  background: rgba(48, 231, 2, 0.1);
  color: var(--green, #30e702);
}
.appoinments-badge-no-show {
  background: rgba(199, 0, 179, 0.1);
  color: #c700b3;
}
.appoinments-badge-lower-no-show {
  color: #c700b3;
}

.appoinments-badge-lower span:first-child {
  color: var(--blue-off-4, #7d7eaa);
  font-family: Roboto;
  font-size: 10px;
  font-weight: 400;
}

.appoinments-badge-lower span:last-child {
  font-family: Roboto;
  font-size: 32px;
  font-weight: 600;
}
.appoinments-badge-lower-new span:last-child {
  background: var(
    --Gradient,
    linear-gradient(4deg, #3e0ffe 3.77%, #5459ff 59.6%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.appoinments-badge-lower-cancelled span:last-child {
  color: #ff332d;
}

.appoinments-badge-lower-completed {
  color: var(--green, #30e702);
}

.assign-appoinment-popup {
  padding: 50px 30px;
}

.assign-appoinment-popup-inner {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 10px;
}

.appointment-sort-containers {
  display: flex;
  align-items: center;
  gap: 15px;
}

.appointment-services-tags {
  flex-wrap: wrap;
  gap: 5px;
}
.appointment-services-data {
  max-width: 150px;
}

@media (max-width: 430px) {
  .clients-container-top.appointments-top-container {
    flex-direction: column;
    align-items: flex-start;
  }
  .appointment-sort-containers {
    align-items: center;
    gap: 4px;
  }
  .appoinments-container span.appoinment-view {
    font-size: 10px;
  }
  .clients-container-top-buttons {
    justify-content: flex-start;
    align-items: flex-end;
    width: 100%;
    gap: 10px;
    margin-bottom: 20px;
  }
  .appoinments-container {
    padding: 0 15px;
  }
}
@media (max-width: 400px) {
  .clients-container-top-buttons {
    justify-content: space-between;
    gap: 4px;
  }
}
