

.team-members-main-container{
    padding: 0 30px;
    padding-left: 20px;
}

.invite-team-member-popup{
    width: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    
    
}
.invite-team-member-buttons{
    width: 100%;
}
.invite-team-member-input{
    width: 100%;
}
.invite-team-member-popup form{
    width: 100%;
    padding: 30px;
}

.team-members-top-buttons{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
@media(max-width:430px){
    .team-members-top-buttons{
        flex-direction: column;
        align-items: flex-start;
    }
}