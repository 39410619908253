.sec7-inner {
  padding: 50px 80px 60px 80px;
  background: #f4f6f8;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
  align-items: flex-start;
}

.sec7-middle {
  display: flex;
  gap: 42px;
}

.first-col,
.second-col {
  display: flex;
  flex-direction: column;
  gap: 29px;
  align-items: flex-start;
}

.first-col a,
.second-col a {
  color: #000;
  text-align: center;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 110% */
}

.sec7-right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

.follow {
  color: var(--Dark, #25233c);
  text-align: center;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 91.667% */
}

.social-icons {
  display: flex;
  gap: 15px;
}

.footer-inner {
  background: #283244;
  padding: 26px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-span {
  color: var(--White, #fff);
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

@media(max-width: 430px){
  .sec7-inner {
    padding: 30px 20px 40px 20px;
  }

  .sec7-inner a img{
    width: 150px;
    height: 35px;
  }

  .sec7-middle{
    margin-bottom: 20px;
  }
  
  .first-col,
  .second-col {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-self: flex-start;
  }
  
  .first-col a,
  .second-col a {
    font-size: 16px;/* 110% */
  }
  
  .sec7-right {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
  
  .follow {
    font-size: 20px; /* 91.667% */
  }
  
  .social-icons {
    display: flex;
    justify-content: flex-start;
    justify-items: flex-start;
    gap: 15px;
  }

  .social-icons a img{
    width: 35px;
  }
  
  .footer-inner {
    background: #283244;
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .footer-span {
    font-size: 14px;
  }
}