.client-settings-eye{
    position: absolute;
    left: 15px;
    top: 14px;
    /* border: 1px solid red; */
}
.client-settings-eye-off{
    position: absolute;
    left: 15px;
    top: 15px;
    /* border: 1px solid red; */
}