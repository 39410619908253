.chart-number {
  color: #000;
  font-family: Roboto;
  font-size: 20px;
  font-weight: 600;
}
.chart-label {
  color: var(--Grey-3, #656263);
  text-align: center;
  font-family: Inter;
  font-size: 13px;
  font-weight: 400;
}
.custom-legend{
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 270px;
  margin: 0 auto;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}

