.add-appoinment-inputs {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  max-width: 1400px;
}

.add-appoinment-input {
  flex: 1 1 270px;
  max-width: 290px;
}
.selected-team-members.selected-services-in-appointments {
  width: 100%;
  height: 30px;
}
.appointment-time-error {
  display: none;
}

.appointment-time-selector:invalid + p.appointment-time-error {
  display: block;
}

.add-appointment-date-picker {
  width: 100%;
  background-color: #F4F6F8;
  font-family: Urbanist;
  border: none;
  padding: 13px;
}

.add-appointment-date-picker .ant-picker-input input::placeholder {
  color: #666666;
}
.add-appointment-date-picker .ant-picker-suffix {
  color: #5459FF;
}

:where(.css-dev-only-do-not-override-3rel02).ant-picker-outlined:focus,:where(.css-dev-only-do-not-override-3rel02).ant-picker-outlined:focus-within{
  background-color: #F4F6F8 !important;
  border: none !important;
}
:where(.css-dev-only-do-not-override-3rel02).ant-picker-outlined:hover{
  background-color: #F4F6F8 !important;
  border: none !important;
}
:where(.css-dev-only-do-not-override-3rel02).ant-picker-outlined:active{
  background-color: #F4F6F8 !important;
  border: none !important;
}
:where(.css-dev-only-do-not-override-3rel02).ant-picker .ant-picker-input >input{
  color: #666;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
}
@media (max-width: 430px) {
  .add-appoinment-input {
    max-width: 100%;
  }
  .outer .add-client-popup{
    margin-top: 250px;
  }
}
