.create-connect-account-main-container{
    min-height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F8F8FF;
}
.create-connect-account-inner{
    width: 600px;
    padding: 30px;
    background-color: #fff;
    border-radius: 10px;
}
.create-connect-account-logo{
    margin-bottom: 20px;
}

.create-connect-account-inner h1{
    margin-bottom: 10px;
    font-size: 25px;
    text-align: center;
    font-weight: 500;
}
.create-connect-account-inner p{
    text-align: center;
    font-size: 15px;
    margin-bottom: 20px;
}

.create-connect-account-btn{
    margin-bottom: 30px;
}