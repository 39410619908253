.services-main-container {
  padding: 0 30px;
  padding-left: 20px;
}

.services-actions-container {
  max-width: 100px;
}

.add-discount-popup {
  width: 500px;
  padding: 30px;
}

.add-discount-popup h3 {
  color: #000;
  font-family: Roboto;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 30px;
}

@media (max-width: 430px) {
  .services-main-container {
    padding-top: 30px;
  }
}
