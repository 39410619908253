.lable-textarea-group {
  width: 100%;
}

.lable-textarea-group label span {
  color: #666;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.edit-client-icon-textarea {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  border-radius: 8px;
  background: var(--Grey-4, #f4f6f8);
  width: 100%;
}

.edit-client-icon-textarea img {
  padding: 13px 0 0 15px;
}

.edit-client-icon-textarea textarea {
  background: transparent;
  padding: 15px 20px;
  color: #666;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: none;
  outline: none;
  resize: none;
  width: 100%;
}

.edit-client-icon-textarea textarea::-webkit-scrollbar {
  width: 8px; /* width of the scrollbar */
}

.edit-client-icon-textarea textarea::-webkit-scrollbar-thumb {
  background-color: #9e9c9c; /* color of the thumb */
  border-radius: 6px; /* roundness of the thumb */
}

.edit-client-icon-textarea textarea::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* color of the track */
}

/* Optional: Handle when the scrollbar is hovered */
.edit-client-icon-textarea textarea::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.notification-setting {
  margin-top: 30px;
}

.notification-heading {
  color: #666;
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 15px;
}

.notificaton-toggle-btn {
  max-width: 1040px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
}

.add-client-inputs {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
  max-width: 1100px;
}
.add-client-input {
  flex: 1 1 270px;
  max-width: 290px;
}
.add-client-popup{
  padding: 20px;
}
@media (max-width: 430px) {
  .edit-client-icon-input {
    width: 100%;
  }
  .add-client-input {
    max-width: 100%;
  }
}
