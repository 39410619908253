.transparent-nav {
  background: transparent;
}

nav.transparent-nav a {
  color: var(--blue-off-3, #f8f8ff);
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.navbar ul li{
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
}