.get-consultation-btn {
  border-radius: 18.5px;
  border: 1px solid var(--Primary, #3e0ffe);
  background: var(--White, #fff);
  padding: 7px 45px;
  color: var(--Primary, #3e0ffe);
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

a.login-btn {
  color: var(--Secondary, #5459ff);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

nav.border-none, div.border-none{
    border: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}
