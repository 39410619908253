.team-member-details-container {
  padding: 0 30px;
  padding-left: 20px;
}
.team-member-details-head {
  color: #000;
  font-family: Roboto;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 0px;
}

.team-member-working-hours-container {
  padding: 20px;
  background: var(--blue-off-3, #f8f8ff);
  border-radius: 7px;
  margin-bottom: 20px;
}

.team-member-working-hours-container h1 {
  color: #666;
  font-family: Roboto;
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: 400;
}

.team-member-working-hours {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  
}
.team-member-working-hour h2 {
  color: var(--blue-off-4, #7d7eaa);
  font-family: Roboto;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 10px;
}
.team-member-working-hour p {
  color: var(--Dark-blue, #1f1b4c);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
}
.team-member-working-hour{
  max-width: 200px;
  flex: 1 1 150px;
}