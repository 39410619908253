/* .standard-services-section1 img.std-services-home-img {
  height: 100%;
  width: 100%;
} */

.standard-services-sec1-overlay {
  background: linear-gradient(71deg, #fff 0.73%, rgba(255, 255, 255, 0) 37.21%);
  /* height: 100%;
  width: 100%; */
  position: absolute;
  /* border: 1px solid red; */
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.standard-services-heading {
  color: var(--White, #fff);
  font-family: Roboto;
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 40px;
}

.standard-services-cards {
  max-width: 1260px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 25px;
  /* border: 1px solid red; */
  position: relative;
  z-index: 2;
  /* margin-top: 150px; */
  padding-bottom: 30px;
}

.standard-service-card {
  border-radius: 10px;
  border: 1px solid #f3f0f0;
  background: #fff;
}

.standard-service-card-img {
  width: 100%;
  height: 190px;
}

.standard-service-card-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px 10px 0px 0px;
}

.standard-service-name-book-btn {
  padding: 15px 17px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.standard-service-name {
  color: var(--White, #000);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
}

.standard-service-book-btn {
  width: 95px;
  height: 32px;
  border-radius: 25px;
  border: 1px solid var(--Primary, #3e0ffe);
  background: #fff;
  color: var(--Primary, #3e0ffe);
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
}

.standard-services-cards-grid{
  display: grid;
  grid-template-columns: repeat(3,1fr);
  max-width: 1320px;
  gap: 20px;
  margin: 20px auto;
  padding-left: 20px;
  padding-right: 20px;
}
.website-services-top-section-standard{
  max-width: 1320px;
  margin: 20px auto;
  padding-left: 20px;
  padding-right: 20px;
}
.website-services-top-section-standard p{
  color: #000;
  font-size: 30px;
  font-weight: 600;

}
@media(max-width:430px){
  .standard-services-cards-grid{
    display: grid;
    grid-template-columns: 1fr;
    max-width: 1320px;
    gap: 20px;
    margin: 20px auto;
    padding-left: 20px;
    padding-right: 20px;
  }
}