.client-portal-tabs-container {
  /* border-bottom: 0.5px solid #666; */
  padding-bottom: 10px;
  padding-right: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  z-index: 1;
}
.client-portal-tabs-option {
  width: 100%;
  cursor: pointer;
  border-radius: 8px;
  padding: 10px 0;
}
.client-portal-tabs-option h3 {
  text-align: center;
  color: #666;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
}

.client-portal-tabs-option.client-portal-tabs-option-active h3 {
  color: #3e0ffe;
  font-weight: 500;
}
.client-portal-tabs-option.client-portal-tabs-option-active {
  border-radius: 8px;
  background: rgba(62, 15, 254, 0.1);
}

@media (max-width: 430px) {
  .client-portal-tabs-option h3 {
    font-size: 12px;
  }
  .client-portal-tabs-container {
    padding-right: 0px;
  }
  .client-portal-tabs-option.client-portal-tabs-option-active::after {
    height: 2px;
    width: 100%;
    position: absolute;
    top: 24px;
  }
}
