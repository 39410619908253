.profiles-head {
  color: #000;
  font-family: Roboto;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 30px;
}
.upload-content {
  /* padding-left: 190px; */
  max-width: 701px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.upload-content-image-section {
  display: flex;
  align-items: center;
  gap: 15px;
  position: relative;
}

.upload-content-title {
  width: 697px;
  height: 62px;
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 800;
  font-size: 53px;
  line-height: 62px;
  text-align: center;
  text-transform: capitalize;
  background: transparent;
  border: none;
  color: rgba(255, 255, 255, 0.2);
  margin-bottom: 30px;
}

.upload-content-image-section input.upload-content-image {
  display: none;
}

.upload-content-image-section label.upload-content-label {
  display: flex;
  width: 149px;
  height: 36px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #3e0ffe;
  color: var(--Primary, #3e0ffe);
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  position: absolute;
  left: 121px;
  top: 30px;
}

.upload-content-image-section label {
  cursor: pointer;
}

.upload-img {
  width: 111px;
  height: 104px;
  /* overflow: hidden; */
  border-radius: 8px;
  border: 1px dashed var(--Grey-2, #3c3963);
  background: var(--blue-off-3, #f8f8ff);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  object-fit: cover;
}

.upload-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.error-message {
  color: red;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  max-width: 111px; /* 183.333% */
}

.upload-photo {
  border-radius: 8px;
  border: 1px dashed var(--Grey-2, #3c3963);
  background: var(--blue-off-3, #f8f8ff);
  width: 111px;
  height: 104px;
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
}

.up-img {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;
}

.up-img img {
  width: 32px;
  height: 33px;
}

span.drag-drop {
  max-width: 79px;
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--Grey-3, #656263);
  text-align: center;
  font-family: Roboto;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 18.5px;
}

.business-profile-head {
  color: var(--Grey-3, #656263);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 15px;
}

.label-change {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 343px;
}

.label-change .change {
  color: var(--Primary, #3e0ffe);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
}

.edit-profile-inputs {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
  margin-bottom: 30px;
  max-width: 1100px;
}
.edit-profile-input {
  flex: 1 1 270px;
  max-width: 290px;
}

.profile-settings-password-container p {
  color: #3e0ffe;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
}

.change-password-popup {
  width: 400px;
  padding: 30px;
}

.change-password-popup h1 {
  color: #000;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 30px;
}

.update-pass-input-container{
  position: relative;
}
.update-password-view-icons{
  position: absolute;
  top: 16px;
  left: 16px;
}
@media (max-width: 430px) {
  .edit-profile-input {
    max-width: 100%;
  }
}
