.privacy-policy-container {
    line-height: 1.6;
    color: #333;
    max-width: 1440px;
    margin: 0 auto;
}
.privacy-policy-inner{
    padding: 20px 80px;

}
.privacy-policy-inner h1 {
    color: #2c3e50;
    /* border-bottom: 2px solid #504EFF; */
    padding-bottom: 10px;
    margin-bottom: 20px;
    text-align: center;
}
.privacy-policy-inner h2 {
    color: #2c3e50;
    margin-top: 30px;
}
.privacy-policy-inner p {
    margin-bottom: 15px;
}
.privacy-policy-inner ul li{
    list-style-type: disc;
}
.privacy-policy-inner ul, .privacy-policy-inner ol {
    margin-bottom: 15px;
    padding-left: 30px;
}
.privacy-policy-inner li {
    margin-bottom: 10px;
}
.privacy-policy-inner .effective-date {
    font-style: italic;
    color: #7f8c8d;
}
.privacy-policy-inner.contact-info {
    background-color: #ecf0f1;
    padding: 15px;
    border-radius: 5px;
    margin-top: 30px;
}
@media (max-width: 600px) {
    .privacy-policy-inner {
        padding: 10px;
    }
    .privacy-policy-inner h1 {
        font-size: 24px;
    }
    .privacy-policy-inner h2 {
        font-size: 20px;
    }
}