.feedback-container {
  padding: 0 30px;
  padding-left: 20px;
}

.add-feedback-popup {
  width: 650px;
  padding: 20px;
}
.feedback-actions {
  max-width: 50px;
}

/* .feedback-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
} */
.feedback-text-container {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100px;
}
