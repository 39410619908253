.payment-success-main-container{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
   
    height: 60vh;
}
.payment-success-main-container h1{
    font-size: 35px;
    text-align: center;
    font-weight: 500;
}

.payment-success-main-container div{
    gap: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px 100px;
    border-radius: 10px;
}