.global-pagination-dropdown-head {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 13px;
  border-radius: 5px;
  border: 1px solid #e9e9e9;
  cursor: pointer;
  background: var(--White, #fff);
}
.global-pagination-container{
    margin-top: 30px;
}

.global-pagination-dropdown-outer {
  height: 40px;
  width: 77px;
  position: relative;
  
}
.global-pagination-dropdown-head span {
  color: #969696;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
}

.global-pagination-middle p {
  color: #969696;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
}
.global-pagination-dropdown-items {
  position: absolute;
  border-radius: 5px;
  border: 1px solid #e9e9e9;
  background: var(--White, #fff);
  width: 100%;
  max-height: 100px;
  overflow-y: auto;

  padding-top: 10px;
  padding-bottom: 10px;
}
.global-pagination-dropdown-item {
  color: #969696;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  padding-left: 11px;
  padding-bottom: 3px;
  padding-top: 3px;
  cursor: pointer;
}

.global-pagination-right button {
  background: transparent;
  cursor: pointer;
}
.global-pagination-right button .global-pagination-icon {
  color: #3e0ffe;
}
.global-pagination-right button:disabled .global-pagination-icon {
  color: #969696;
}
