.section-one {
  max-width: 1440px;
  position: relative;
  height: 550px;
  /* padding-top: 100px; */
}

.section-one img {
  width: 100%;
}

.home-overlay {
  padding-left: 79px;
  position: absolute;
  top: 0;
  background: linear-gradient(94deg, #fff 27.64%, rgba(217, 217, 217, 0) 100%);
  /* height: 486px; */
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.home-overlay .overlay-head {
  max-width: 500px;
  color: #000;
  font-feature-settings: "clig" off, "liga" off;

  /* H1 Semibold 48 */
  font-family: Roboto;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  /* 125% */
  margin-top: 104px;
  background: transparent;
  border: none;
}
.home-overlay .overlay-head::-webkit-resizer,
.overlay-para::-webkit-resizer,
.para::-webkit-resizer {
  display: none;
}
.overlay-head {
  background: transparent;
  border: none;
  resize: none;
  color: #000;

  font-feature-settings: "clig" off, "liga" off;
  /* H2 Medium 34 */
  font-family: Roboto;
  font-size: 34px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.mobile-para {
  background: transparent;
  border: none;
  resize: none;
  height: auto;
  width: 100%;
  height: 150px;
  text-align: justify;
}
textarea:focus {
  outline: none;
}
.overlay-para {
  margin-top: 20px;
  width: 564px;
  color: #666;
  font-feature-settings: "clig" off, "liga" off;

  /* Text Regular 18 */
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 127.188%;
  /* 22.894px */
  height: 100px;
  background: transparent;
  border: none;
}

.book-appointment-btn {
  margin-top: 34px;
}

.mobile-responsive {
  display: none;
}

/* Section two */
.review {
  text-align: center;
  color: var(--Dark, #25233c);
  font-feature-settings: "clig" off, "liga" off;

  /* H2 Medium 34 */
  font-family: Roboto;
  font-size: 34px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 50px;
}

.section-two .para {
  margin: 25px auto;
  width: 862px;
  color: #666;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;

  /* Text Regular 16 */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: transparent;
  border: none;
}

.update-banner-image-input {
  position: absolute;
  top: 100px;
  right: 20px;
}
.update-banner-image-input div {
  color: var(--White, #fff);
  font-feature-settings: "clig" off, "liga" off;

  /* Text Regular 18 */
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 127.188%; /* 22.894px */
  border: 1px dashed #fff;
  padding: 10px;
}
.update-banner-image-input input {
  display: none;
}
.update-banner-image-input label {
  cursor: pointer;
}
/* Carusal  */

.website-color-picker {
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 2;
}
.website-color-picker label {
  color: #fff;

  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.Home-slider {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 80px;
}
.home-slider-prev {
  position: absolute;
  left: 80px;
}
.home-slider-next {
  position: absolute;
  right: 80px;
}
.home-slider-prev,
.home-slider-next {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}
.home-slider-inner .swiper {
  /* max-width: 100%; */
  height: 10%;
  /* border: 1px solid red; */
  padding: 0px 10px;
  /* margin: 0 100px; */
  overflow: hidden;
  position: static;
}

.card {
  max-width: 465px;
  font-size: 18px;
  background: #fff;
  margin: 10px 10px 10px 10px;
  border-radius: 10px;
  padding: 30px;

  /* Shadow 1 */
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.15);

  /* Center slide text vertically */
  display: flex;
  flex-direction: column;
}

.home-slider-inner .swiper-slider .card p {
  color: #666;
  font-feature-settings: "clig" off, "liga" off;

  /* Text Regular 16 */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 408px;
}

.Home-slider .home-slider-inner .swiper button.swiper-button-next {
  position: absolute;
  right: 100px;
}

.Home-slider .home-slider-inner .swiper button.swiper-button-prev {
  position: absolute;
  left: 100px;
}

.Home-slider button.swiper-button-next,
.Home-slider button.swiper-button-prev {
  background-color: white;
  background-color: rgba(255, 255, 255, 0.5);
  right: 10px;
  padding: 30px;
  color: #000 !important;
  fill: black !important;
  stroke: black !important;
  border-radius: 350px;
  background: #3e0ffe;
  font-size: 10px;
  width: 56px;
  height: 56px;
  /* margin-right: -100px; */
}

.home-slider-inner {
  max-width: 960px;
  margin: 0 auto;
  /* border: 1px solid green; */
  overflow: hidden;
}

.Home-slider .swiper button.swiper-button-prev::after {
  font-size: 20px;
  stroke-width: 3px;
  stroke: #fff;
  color: #fff;
}

.Home-slider .swiper button.swiper-button-next::after {
  stroke-width: 3px;
  stroke: #fff;
  color: #fff;
  font-size: 10px;
}

.image-rating {
  padding: 20px 0;
  display: flex;
  gap: 20px;
}

.image-rating img {
  width: 32px;
  height: 32px;
}

.image-rating .ratings h3 {
  color: #25233c;
  font-feature-settings: "clig" off, "liga" off;

  /* H5 SB 16 */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 5px;
}

.ratings img {
  width: 57px;
  height: 9px;
}

/* Section two */
/* .mobile-responsive {
    display: none;
} */

.save-button-website {
  position: fixed;
  bottom: 45px;
  right: 0px;
  z-index: 1000;
}

.review-text-edit-btn {
  position: absolute;
  right: 150px;
  top: 20px;
  z-index: 1000;
}
.use-default-button-container {
  position: absolute;
  right: 20px;
  top: 160px;
}

.use-default-button-container button {
  color: var(--White, #fff);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 127.188%;
  border: 1px dashed #fff;
  padding: 5px 10px;
  background: transparent;
}
@media (max-width: 1200px) {
  .home-slider-prev {
    left: 20px;
  }
  .home-slider-next {
    right: 20px;
  }
}

@media (max-width: 431px) {
  .review-text-edit-btn {
    left: 10px;
    transform: translateY(-15px);
  }

  .para {
    height: 100px;
  }
  .Home-slider {
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 1px solid red; */
  }
  .Home-slider .home-slider-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 1px solid red; */
  }
  .Home-slider .home-slider-inner .swiper .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .home-slider-prev {
    display: none;
  }
  .home-slider-next {
    display: none;
  }
  /* .home-slider-inner .swiper .swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    justify-content: center;
    gap: 0px;
    transition-property: transform;
    transition-timing-function: var(
      --swiper-wrapper-transition-timing-function,
      initial
    );
    box-sizing: content-box;
  } */

  .card {
    padding: 0 14.4px;
  }
  .image-rating img {
    width: 23.035px;
    height: 23.035px;
  }

  .ratings h3 {
    color: var(--Dark, #25233c);
    font-feature-settings: "clig" off, "liga" off;
    font-family: Roboto;
    font-size: 11.517px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .ratings img {
    width: 41.03px;
    height: 6.478px;
  }

  .card p {
    color: #666;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Roboto;
    font-size: 11.517px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 293.69px;
  }

  .Home-slider .swiper button.swiper-button-next,
  .Home-slider .swiper button.swiper-button-prev {
    display: none;
    width: 32px;
    height: 32px;
    padding: 10px;
  }

  /* .Home-slider {
        border: 1px solid red;
    } */

  .section-one {
    display: none;
  }

  .section-two {
    display: block;
    padding-top: 30px;
  }

  .mob-img {
    width: 100%;
    max-height: 194px;
    margin-bottom: 18px;
  }

  .mobile-section-2 {
    max-width: 431px;
    max-height: 194px;
    position: relative;
  }

  .mob-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(94deg, #fff 1%, rgba(217, 217, 217, 0) 100%);
    z-index: 1;
  }

  .mobile-section-one {
    margin-top: 88px;
    padding-left: 20px;
    padding-right: 35px;
  }

  .mobile-responsive {
    display: block;
  }

  .mobile-heading {
    max-width: 375px;
    color: #000;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Roboto;
    font-size: 34px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .mobile-para {
    margin-top: 16px;
    color: #666;
    max-width: 352px;
    font-family: Roboto;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

  .mobile-btn {
    margin-left: 20px;
    margin-top: 16px;
    margin-bottom: 16px;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 25px;
    border: 1px solid var(--Primary, #3e0ffe);
    background: #fff;
    color: var(--Primary, #3e0ffe);
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    padding: 5px 20px;
    /* 183.333% */
  }
  .mobile-responsive.overlay-head {
    width: 100%;
  }
}
@media (max-width: 401px) {
  .home-slider-inner .swiper .swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    justify-content: space-around;
    /* gap: 150px; */
    transition-property: transform;
    transition-timing-function: var(
      --swiper-wrapper-transition-timing-function,
      initial
    );
    box-sizing: content-box;
  }

  .image-rating img {
    width: 23.035px;
    height: 23.035px;
  }

  .ratings h3 {
    color: var(--Dark, #25233c);
    font-feature-settings: "clig" off, "liga" off;
    font-family: Roboto;
    font-size: 11.517px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .ratings img {
    width: 41.03px;
    height: 6.478px;
  }

  .card p {
    color: #666;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Roboto;
    font-size: 11.517px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 293.69px;
  }

  .home-slider-inner .card {
    padding: 0 14.4px;
    /* border: 1px solid green; */
    width: 324px;
    /* max-height: 137px; */
    font-size: 18px;
    background: #fff;
    margin: 10px 10px 10px 10px;
    border-radius: 10px;
    padding: 14px;

    /* Shadow 1 */
    box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.15);

    /* Center slide text vertically */
    display: flex;
    flex-direction: column;
    /* margin-left: 105px; */
  }
  .home-slider-inner {
    display: flex;
    justify-content: center;
    gap: 100px;
  }

  .section-one {
    display: none;
  }

  .section-two {
    display: block;
  }

  .mob-img {
    width: 100%;
    height: 100%;
    margin-bottom: 18px;
  }

  .mobile-section-2 {
    max-width: 431px;
    max-height: 194px;
    position: relative;
  }

  .mob-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(94deg, #fff 1%, rgba(217, 217, 217, 0) 100%);
  }

  .mobile-section-one {
    margin-top: 88px;
    padding-left: 20px;
    padding-right: 35px;
  }

  .mobile-responsive {
    display: block;
  }

  .mobile-heading {
    max-width: 375px;
    color: #000;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Roboto;
    font-size: 34px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .mobile-para {
    margin-top: 16px;
    color: #666;
    max-width: 352px;
    font-family: Roboto;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

  .mobile-btn {
    margin-left: 20px;
    margin-top: 16px;
    margin-bottom: 16px;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 25px;
    border: 1px solid var(--Primary, #3e0ffe);
    background: #fff;
    color: var(--Primary, #3e0ffe);
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    padding: 5px 20px;
    /* 183.333% */
  }
}

@media (max-width: 374px) {
  .home-slider-inner .card {
    padding: 0 9.4px;
    /* border: 1px solid green; */
    width: 314px;
    /* max-height: 137px; */
    font-size: 18px;
    background: #fff;
    margin: 10px 10px 10px 10px;
    border-radius: 10px;
    padding: 14px;

    /* Shadow 1 */
    box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.15);

    /* Center slide text vertically */
    display: flex;
    flex-direction: column;
    /* margin-left: 97px; */
  }
}
