.admin-pricing-head {
  border-radius: 8px;
  background: var(--blue-off-3, #f8f8ff);
  padding: 10px 25px;
}

.admin-pricing-head-title {
  color: #000;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.admin-pricing-body {
  display: flex;
  justify-content: space-between;
  padding: 15px 10px 20px 0px;
  flex-wrap: wrap;
}

/* .admin-pricing-ul {
  flex: 1 1 350px;
} */

.admin-pricing-ul{
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.admin-pricing-ul li {
  list-style: disc;
  color: #292d32;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.053px;
  max-width: 470px;
  /* border: 1px solid red; */
  margin-left: 50px;
  padding: 5px 0;
}
.update-pricing-popup{
  width: 800px;
  padding: 30px;
}
.update-pricing-popup h1{
  color: #292d32;
  font-family: Poppins;
  font-size: 25px;
  font-weight: 500;
  margin-bottom: 30px;
}

.update-pricing-inputs{
  display: flex;
  align-items: center;
  gap: 10px;
}
.update-pricing-input{
  width: 50%;
}
@media (max-width: 430px) {
    .admin-pricing-head {
        padding: 8px 15px;
      }
  .admin-pricing-head-title {
    font-size: 15px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.053px;
  }
  .admin-pricing-ul{
    display: grid;
    grid-template-columns: 1fr;
  }
}
