.business-refferals-container {
  padding: 0 30px;
  padding-left: 20px;
}

.business-refferals-email {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 150px;
}
