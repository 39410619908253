.checkbox-component{
    display: flex;
    align-items: center;
    gap: 10px;
}

.checkbox-component input{
    width: 16px;
    height: 16px;
    
}

.checkbox-component label{
    color: var(--Dark, #25233C);
font-family: Roboto;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 127.188%; 
cursor: pointer;
}