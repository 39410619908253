.intake-form-checkboxes {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 15px;
}
.intake-question-head {
  color: #000;
  font-family: Roboto;
  font-size: 20px;
  font-weight: 600;
}

.intake-question-input {
  height: 36px;
  width: 100%;
  padding: 8px 15px;
  border-radius: 8px;
  background: var(--Grey-4, #f4f6f8);
  margin-top: 15px;
  margin-bottom: 28px;
}

.intake-form-btns {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
}

.questions-container {
  background: var(--blue-off-3, #f8f8ff);
  padding: 11px 20px;
  align-items: center;
  gap: 10px;
}
.questions-container h1 {
  color: #000;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
}
.intake-questions{
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
@media (max-width: 1220px) {
  .intake-form-checkboxes {
    grid-template-columns: 1fr 1fr;
  }
}
@media (max-width: 431px) {
  .intake-form-checkboxes {
    grid-template-columns: 1fr;
  }
  .outer.custom-intake-form-container{
    padding-top: 30px;
  }
}
