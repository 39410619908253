.global-input-container {
}
.global-input-container-input input {
  border-radius: 8px;
  background: var(--Grey-4, #f4f6f8);
  color: #666;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  padding: 15px;
  width: 100%;
}
.global-input-container label {
  color: #666;
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
}
.global-input-container-input-with-image {
  position: relative;
}
.global-input-container-input-with-image input {
  padding-left: 50px;
}
.global-input-container-input-with-image img {
  position: absolute;
  top: 28%;
  left: 15px;
}

.global-input-error {
  white-space: nowrap;
  font-family: Roboto, "San-serif";
  font-size: 11px;
  color: #FF0000;
  height: 10px;
}
