.hero-section .hero-head {
    padding-top: 100px;
    text-align: center;
    color: #000;
    font-family: Roboto;
    font-size: 34px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.cards {

    padding-left: 81px;
    padding-right: 81px;
    margin-bottom: 31px;
    /* margin-top: 73px; */
    display: flex;
    justify-content: space-between;
    gap: 25px;
    align-items: center;
    flex-wrap: wrap;
}

.hero-section .card-heading {
    /* margin-top: 73px; */
    margin-bottom: 20px;
    padding-left: 81px;
    color: #000;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.h-s {
    /* margin-top: 73px; */
    margin-bottom: 20px;
    padding-left: 81px;
    color: #000;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.card1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* margin-left: 42px; */

}

.card1 img {
    width: 100%;
    height: 202px;
}

.hero-section .cards .card1 {
    max-width: 394.289px;
    flex: 1 1 280px;
    height: auto;
    flex-shrink: 0;
    border: 1px solid #DDD;
    background: var(--White, #FFF);
}

.card1 .services-card-p {
    /* margin-top: 27px; */
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
}

.book-now-btn {
    color: var(--Primary, #3E0FFE);
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 183.333% */
    margin-top: 25px;
    margin-bottom: 23px;
    border-radius: 25px;
    border: 1px solid var(--Primary, #3E0FFE);
    background: #FFF;
    display: flex;
    width: 103px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 5px 20px;

}
.service-info{
    width: 100%;
    margin-bottom: 10px;
}

.service-card-inner{
    padding: 10px;
    width: 100%;
}
.service-category-badge{
    position: absolute;
    top: -10px;
    right: 0px;
    padding: 3px 15px;
    border-radius: 20px;
}
.service-category-badge p{
    font-size: 11px;
}

.website-selected-services{
    margin-bottom: 30px;
}

.classic-template-services-container{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 30px;
    padding: 0 80px;
}
.website-services-top-section{
    padding: 0 80px;
    margin-bottom: 30px;
}
@media(max-width:431px) {

 

    .hero-section .hero-head {
        padding-top: 75px;
        margin-bottom: 20px;
        text-align: center;
        color: #000;

        /* SB 28 */
        font-family: Roboto;
        font-size: 28px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
   .cards .card1{
        background-color: red;
    }

    .cards {

        padding-left: 20px;
        padding-right: 20px;
        margin-bottom: 31px;
        /* margin-top: 73px; */
        display: flex;
        justify-content: space-between;
        gap: 25px;
        align-items: center;
        flex-wrap: wrap;
    }

    .hero-section .cards .card1 {
        max-width: 394.289px;
        flex: 1 1 280px;
        height: auto;
        flex-shrink: 0;
        border: 1px solid #DDD;
        background: var(--White, #FFF);
        margin: 0 auto;
    }

    .hero-section .cards .card1 {
        max-width: 305px;
        height: auto;
        flex-shrink: 0;
        border: 1px solid #DDD;
        background: var(--White, #FFF);
    }

    .card1 img {
        height: 153px;
        width: 100%;
    }

    .card1 .services-card-p {
        color: #000;
        text-align: center;
        font-family: Poppins;
        font-size: 12.188px;
        font-style: normal;
        font-weight: 400;
        line-height: 11.426px;
        /* 93.75% */
    }
   
    .hero-section .card-heading {
        /* margin-top: 73px; */
        margin-bottom: 20px;
        padding-left: 20px;
        color: #000;
        text-align: center;
        font-family: Roboto;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
    .classic-template-services-container{
        display: grid;
        grid-template-columns: 1fr;
        gap: 30px;
        padding: 0 30px;
    }

}