.admin-dashboard-line-chart{
    border-radius: 5px;
background: var(--blue-off-3, #F8F8FF);
display: flex;
align-items: center;
justify-content: flex-start;
/* width: 100%; */
}

.admin-dashboard-line-chart .recharts-wrapper{
    position: relative;
    cursor: default;
    width: 100%;
    height: 100%;
    max-height: 300px;
    max-width: 785px;
}

.admin-dashboard-lower-section{
    margin-top: 20px;
  display: flex;
  gap: 20px;
}

.admin-dashboard-piechart{
    width: 100%;
  height: 200px;
}

.admin-dashboard-piechart {
    border-radius: 8px;
    background: var(--White, #fff);
    height: 300px;
    width: 100%;
    margin-bottom: 20px;
  }

  .admin-today-bookings {
    border-radius: 8px;
    background: var(--White, #fff);
    padding: 30px 20px;
  }


@media (max-width: 1150px) {
    .admin-dashboard-lower-section {
      gap: 10px;
    }
  }


@media(max-width: 430px){
    .admin-dashboard-lower-section {
        margin-top: 18px;
        display: flex;
        flex-wrap: wrap;
        gap: 0px;
    }
    .admin-dashboard-piechart {
        border-radius: 8px;
        background: var(--White, #fff);
        height: 300px;
        width: 100%;
        margin-bottom: 20px;
      }
    
      .admin-today-bookings {
        border-radius: 8px;
        background: var(--White, #fff);
        padding: 30px 20px;
        
      }

      
}