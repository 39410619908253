.standard-about-sec1-overlay {
  height: 100%;
  width: 100%;
  background: rgba(211, 211, 211, 0.05);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.standard-about-sec1-overlay-inner {
  max-width: 1100px;
  margin: 0 auto;
  padding: 150px 20px 246px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  position: relative;
  z-index: 1;
}
.standard-about-sec1-overlay-inner textarea{
  width: 700px;
  height: 100px;
}


.standard-about-heading {
  color: var(--White, #fff);
  font-family: Roboto;
  font-size: 34px;
  font-weight: 700;
  background: transparent;
  text-align: center;
}

.std-about-para {
  width: 100%;
  color: var(--White, #fff);
  text-align: center;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 400;
  line-height: 127.188%;
  background: transparent;
  resize: none;
  border: none;
}

.standard-about-section2 {
  padding: 75px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 70px;
}

.standard-about-sec2-head {
  width: 100%;
  text-align: center;
  color: #000;
  font-family: Roboto;
  font-size: 34px;
  font-weight: 400;
  line-height: 22px;
}

.standard-about-sec2-details {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.standard-about-sec2-details-left {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 15px;

  border: none;
}

.standard-about-sec2-details-para1 {
  color: #666;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  width: 100%;
  height: 150px;
  resize: none;
  border: none;
}

.standard-about-sec2-details-right{
  display: flex;
  gap: 25px;
  width: 40%;
  border: none;
  position: relative;
}

.about-sec2-details-right-col1{
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.details-right-col-small-img{
  width: 259px;
  height: 155px;
}
.standard-about-sec2-details-right{
  height: 400px;
}
.standard-about-sec2-details-right img{
  width: 100%;
  height: 100%;
  /* object-fit: cover; */
}
.details-right-col-large-img{
  width: 259px;
  height: 225px;
}

.details-right-col-large-img img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.standard-about-section1 img{
  width: 100%;
  height: 100%;
}

.standard-template-switch{
  position: absolute;
  left: 30px;
  bottom: 0;
}
.standard-team-overlay{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 1;
}
@media (max-width: 431px){

  .standard-about-section1{
    height: 70vh;
  }
  
  .standard-about-sec1-overlay-inner {
    padding: 150px 20px 30px 20px;
  }
  
  .standard-about-heading {
    font-size: 24px;
    font-weight: 500;
  }
  
  .std-about-para {
    font-size: 16px;
    line-height: 22px;
    max-width: 330px;
  }
  
  .standard-about-section2 {
    padding: 20px;
    gap: 20px;
  }
  
  .standard-about-sec2-head {
    text-align: left;
    font-size: 24px;
    font-weight: 500;
    line-height: normal;
  }
  
  .standard-about-sec2-details {
    width: 100%;
    align-items: center;
    /* justify-content: center; */
    gap: 50px;
  }
  
  .standard-about-sec2-details-left {
    gap: 10px;
    width: 100%;
  }
  
  .standard-about-sec2-details-para1 {
    line-height: normal;
    
  }
  
  .standard-about-sec2-details-right{
    gap: 10px;
    width: 100%;
    height: 200px;
  }
  
  .about-sec2-details-right-col1{
    gap: 8px;
  }
  
  /* .details-right-col-small-img{
    width: 140px;
    height: 90px;
    border: 1px solid;
  }
  
  .details-right-col-large-img{
    width: 140px;
    height: 130px;
  } */
  
}


