.appointment-calender-date-picker {
  border-radius: 8px;
  color: #666;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  padding: 9px;
  width: 100%;
  border: 1px solid #666;
}

.appoinment-view-calender {
  font-size: 15px;
  font-family: "Roboto";
  color: #666666;
}
.appointment-calender-main {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  overflow-x: auto;
  gap: 10px;
}

.appointment-team-schedule {
  border-radius: 5px;
  background: #8bc7ff;
  margin-bottom: 10px;
  padding: 30px 15px;
  width: 250px;
}
.appointment-calender-badge {
  color: var(--White, #fff);
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  line-height: 22px;
  padding: 5px 15px;
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(7.5px);
  display: inline-block;
  margin-bottom: 10px;
}
.appointment-calender-service{
  color: var(--White, #fff);
  background: rgba(255, 255, 255, 0.3);
  padding: 2px 10px;
  border-radius: 10px;
  font-size: 11px;
}
.appointment-calender-heading {
  color: #000;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
}
.appointment-calender-service-name {
  color: #000;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}
.appointment-calender-team {
  margin-bottom: 30px;
}
.appointment-calender-team p {
  color: #000;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 400;
}
.appointment-by-member-empty {
  border: 1px solid red;
}

.appointment-calender-row {
  position: relative;
}

.appointment-calender-row span {
  position: absolute;
  left: -100px;
  top: 0;
  color: var(--blue-off-4, #7d7eaa);
  text-align: right;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  display: block;
}

.appointments-calender-main-container {
  margin-left: 107px;

}
.appointment-week-message{
  margin-bottom: 40px;
}
.appointments-calender-main-container table{
  border-collapse: collapse;
}
.appointment-calender-row td {
  border: 0.5px solid var(--Blue-off-0, rgba(84, 89, 255, 0.4));
  background: var(--White, #fff);
  padding: 5px;
}

.appointment-calender-container{
  overflow-x: auto;
}