.client-appoinment-detail-container {
  padding: 0 30px;
  padding-left: 20px;
}
.client-appoinment-details-head {
  color: #000;
  font-family: Roboto;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 30px;
}

.client-detail-appoinment-buttons {
  margin-top: 40px;
}

.appointment-notes {
  border-radius: 8px;
  background: var(--blue-off-3, #f8f8ff);
  padding: 15px;
}

.appointment-notes h2 {
  color: #000;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
}
