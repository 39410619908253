@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Kantumruy+Pro:wght@100;200;300;400;500;600;700&family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,900;0,1000;1,800&family=Poppins:wght@100;200;300;400;500;600;700;800&family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,100&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Send+Flowers:wght@100;200;300;400;500;600;700;800;900&display=swap");
* {
  padding: 0;
  margin: 0;
  text-decoration: none;
  box-sizing: border-box;
  font-family: Roboto;
}
input {
  border: none;
}
input:focus {
  outline: none;
}
select {
  border: none;
}
select:focus {
  outline: none;
}
button {
  border: none;
  cursor: pointer;
}
li {
  list-style: none;
}
.global-empty-para {
  color: #000;
  font-family: Roboto;
  font-size: 11px;
  font-weight: 400;
}
.global-empty-para a {
  color: #5459ff;
}
.global-empty-para button {
  color: #5459ff;
  background: transparent;
}
.border {
  border: 1px solid red;
}
.paragraphs {
  color: var(--Grey-3, #656263);
  text-align: center;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 127.188%;
}

.headings {
  color: #25233c;
  font-family: Roboto;
  font-size: 34px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
}

.max-width-1440 {
  max-width: 1440px;
  margin: 0 auto;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-40 {
  margin-bottom: 40px;
}

/* paddings */

.pt-30 {
  padding-top: 30px;
}
.pt-20 {
  padding-top: 20px;
}
.pt-10 {
  padding-top: 10px;
}

/* DropDown Styles */
.dropdown-container {
  margin-bottom: 20px;
}
.dropdown-container h2 {
  color: #666;
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 14px;
}
.drop-inner {
  border-radius: 8px;
  background: var(--White, #fff);
  position: relative;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 60px;
  cursor: pointer;
  z-index: 2;
}

.drop-hidden {
  height: auto;
  overflow: auto;
  position: absolute;
  background-color: #fff;
  left: 0;
  right: 0;
  padding-left: 60px;
  transition: max-height 0.5s ease;
  margin-top: 20px;
  border-radius: 8px;
  background: var(--White, #fff);
}
.drop-hidden::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.drop-hidden::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.drop-hidden::-webkit-scrollbar-thumb {
  background: #bababa;
}

/* Handle on hover */

.drop-hidden .drop-option {
  padding-top: 5px;
  padding-bottom: 10px;
}
.drop-hidden .drop-option:first-child {
  padding-top: 20px;
}
.drop-inner img {
  position: absolute;
  left: 13px;
  top: 13px;
}
.drop-icon {
  position: absolute;
  right: 13px;
  top: 13px;
}
.drop-inner .drop-option-active,
.drop-inner .drop-option {
  color: #666;

  font-family: Libre Franklin;
  font-size: 16px;
  font-weight: 400;
}

/* Error */
.error {
  position: absolute;
  bottom: -30px;
  font-family: Roboto;
  font-size: 12px;
  color: #e90000;
}
/* button styels */

.w-100 {
  width: 100%;
}

.global-input-info {
  color: #666;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.global-overlay {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  /* z-index: 1; */
}
.react-datepicker-wrapper{
  width: 100% !important;
}
.image-size-constraints{
  font-size: 13px;
  color: #666666;
}
@media (max-width: 430px) {
  .drop-inner {
    background: var(--Grey-4, #f4f6f8);
  }

  .headings {
    font-size: 24px;
    font-weight: 400;
    line-height: 22px;
  }

  .paragraphs {
    color: var(--Grey-3, #656263);
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal; /* 22.894px */
  }
}
