#edit-voucher-lables-inputs {
  max-width: 1080px;
}

.edit-client-icon-input select {
  background: transparent;
  display: flex;
  justify-content: space-between;
  padding-right: 80px;
  color: #666;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.edit-voucher-inputs{
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  max-width: 1000px;
  margin-bottom: 40px;
}
.edit-voucher-input{
  flex: 1 1 270px;
  max-width: 290px;
}

@media(max-width:430px){
  .edit-voucher-input{
      max-width: 100%;
  }
}