.standard-template-consultation {
  padding: 134px 20px 125px 20px;
}

.standard-consultation-heading {
  text-align: center;
  color: #000;
  font-family: Roboto;
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 50px;
}

.standard-consultation-form {
  max-width: 1300px;
  margin: 0 auto;;
  gap: 17px;
}

.standard-template-form-btns {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  margin-top: 50px;
}

.standard-tempt-form-btn-cancel {
  width: 127px;
  height: 36px;
  border-radius: 18.5px;
  border: 1px solid #757575;
  background: var(--White, #fff);
  color: #757575;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
  cursor: pointer;
}

.standard-tempt-form-btn-submit {
  width: 127px;
  height: 36px;
  border-radius: 18.5px;
  background: #757575;
  border: none;
  color: var(--White, #fff);
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
  cursor: pointer;
}

@media (max-width: 431px){
    .standard-template-consultation {
        padding: 100px 20px 50px 20px;
      }
      
      .standard-consultation-heading {
        text-align: center;
        font-size: 24px;
        font-weight: 500;
        margin-bottom: 30px;
      }
      
}
