
.edit-service-inputs{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    max-width: 1100px;
    align-items: center;
}

.selected-team-members{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
    
}

.duration-mask{
    border-radius: 8px;
    background: var(--Grey-4, #f4f6f8);
    color: #666;
    height: 100%;
    padding-left: 50px;
    z-index: 1;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    padding: 15px;
}

.service-category-drop{
    position: relative;
}

.service-category-drop button.fill-previous-btn{
    top: -5px;
}