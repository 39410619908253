.clients-container-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}
.clients-container-top-buttons {
  display: flex;
  align-items: center;
  gap: 15px;
}
.clients-container {
  padding-left: 30px;
  padding-right: 30px;
  /* min-height: 120vh; */
}

/* Popup */

.client-popup {
  width: 586px;
  height: 302px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.client-popup h1 {
  color: #333;
  text-align: center;
  font-family: Roboto;
  font-size: 24px;
  font-weight: 500;
}
.import-clients-popup{
  width: 400px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.import-clients-popup form input{
}
@media (max-width: 430px) {
  .edit-client-heading {
    font-size: 30px;
    font-weight: 700;
  }
  .clients-container-top{
    margin-bottom: 10px;
  }
  .client-popup {
    width: 320px;
    height: 220px;
  }
  .client-popup h1 {
    font-size: 20px;
  }
  .clients-container-top.clients-container-inner{
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 30px;
  }
  .clients-container {
    padding:0 15px;
  }
}
