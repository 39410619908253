.input-group .forget-pass {
    position: absolute;
    right: 0;
    bottom: -30px;
    color: var(--Primary, #3e0ffe);
    font-family: Roboto;
    font-size: 12px;
    font-weight: 400;
  }
  
  .login-logo {
    color: #000;
    font-family: Roboto;
    font-size: 34px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 70px;
  }
  .signup-text {
    color: var(--Grey-3, #656263);
    font-family: Roboto;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    margin-top: 50px;
  }
  .signup-text span{
    color: #3E0FFE;
    font-weight: 600;
  } 
  