.global-client-dropdown {
  width: 100%;
  position: relative;
}
.global-client-dropdown h2 {
  color: #666;
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  transform: translateY(-10px);
}


.global-client-drop-input {
  border-radius: 8px;
  background: var(--Grey-4, #f4f6f8);
  padding: 13px;
}
.global-client-drop-input-left h3 {
  color: #666;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
}
.global-client-drop-options {
  position: absolute;
  z-index: 3;
  margin-top: 10px;
  border-radius: 8px;
  background: var(--White, #fff);
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.15);
  width: 250px;
  /* padding: 10px; */
}
.global-client-drop-options input {
  padding: 17px 12px;
  border-radius: 8px;
  background: var(--Grey-4, #f4f6f8);
  width: 100%;
  position: sticky;
  top: 0px;
}

.global-client-drop-options-inner {
  overflow: auto;
  max-height: 200px;
}
.global-client-drop-options-inner::-webkit-scrollbar {
  width: 5px;
}
.global-client-drop-options-inner::-webkit-scrollbar-track {
  background-color: #fff;
}
.global-client-drop-options-inner::-webkit-scrollbar-thumb {
  background: var(--Grey-4, #f4f6f8);
}

.global-client-drop-options-inner {
  padding: 10px;
}

.global-clients-drop-user {
  width: 24px;
  height: 24px;
  background: var(--blue-off-1, rgba(84, 89, 255, 0.1));
  border-radius: 50%;
}
.global-client-drop-option span {
  color: #000;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
}
.global-client-drop-option {
  padding: 5px;
  padding-bottom: 7px;
  border-bottom: 0.5px solid var(--Grey-5, #cdcdcd);
}
.global-client-drop-option span{
    cursor: pointer;
}
.global-client-drop-option span:hover{
    color: #5459FF;
}

.global-client-drop-option span.client-drop-option-active{
    color: #5459FF;
}