.pricing-inner {
    padding: 130px 80px 44px 80px;
  }
  
  .pricing-heading {
    margin-bottom: 25px;
  }
  
  .pricing-para {
    text-align: center;
    max-width: 849px;
    margin: 0 auto;
    color: #666;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 30px;
  }
  
  .pricing-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 10px;
  }
  
  .pricing-card {
    border-radius: 35px 10px;
    border: 1px solid #d9d9d9;
    background: var(--White, #fff);
    max-width: 412px;
    /* max-height: 555px; */
    flex: 1 1 350px;
  }
  
  .pricing-card:nth-child(2){
      max-height: 491px;
  }
  .pricing-card:nth-child(3){
      max-height: 343px;
  }
  
  .pricing-card-head {
    border-radius: 35px 10px 0px 0px;
    background: #eeefff;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 5px;
  }
  .pricing-card-head h3{
    font-size: 16px;
    font-weight: 400;
  }
  .pricing-card-heading {
    color: #000;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  
  .pricing-card-items {
    padding: 20px 10px 11px 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .pricing-card-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
  }
  
  .item-name {
    color: #000;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 137.5% */
  }
  
  .card-msg {
    color: #666;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; 
    text-align: center;
    margin-bottom: 10px;
  }


  @media (min-width: 1000px) and (max-width: 1300px){
    .pricing-inner {
      padding: 130px 20px 44px 20px;
    }
    .pricing-card {
      flex: 1 1 300px;
    }
  }

  @media (max-width: 430px){
    .pricing-inner {
      padding: 113px 20px 40px 20px;
    }
    
    .pricing-heading {
      margin-bottom: 25px;
    }
    
    .pricing-para {
      line-height: normal;
    }
    .pricing-cards {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 25px;
    }
    
    .pricing-card {
      border-radius: 25px 10px;
      border: 1px solid #d9d9d9;
      background: var(--White, #fff);
      max-width: 412px;
      /* max-height: 555px; */
    }
    
    .pricing-card:nth-child(2){
        max-height: 530px;
    }
    .pricing-card:nth-child(3){
        max-height: 360px;
    }
    
    .pricing-card-head {
      border-radius: 25px 10px 0px 0px;
    }
    
    .pricing-card-items {
      padding: 20px 10px 10px 20px;
    }
    
    .pricing-card-item {
      align-items: flex-start;
    }

    
    .item-name {
      line-height: normal; /* 137.5% */
    }
  }
  