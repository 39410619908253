.blog-inner {
    padding: 150px 80px 50px 80px;
  }
  
  .blog-heading {
    margin-bottom: 7px;
  }
  
  .blogs-para {
    color: #666;
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    max-width: 849px;
    margin: 0 auto;
    margin-bottom: 50px;
  }
  
  .blog-posts {
    display: flex;
    flex-direction: column;
    gap: 50px;
  }
  
  .blog {
    display: flex;
    flex-direction: column;
    /* align-items: flex-start; */
    gap: 25px;
  }

  .blog-details{
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 25px;
    flex-wrap: wrap-reverse;
  }

  .blog:nth-child(even) .blog-details{
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
  }

  .blog-details .blog-image{
    width: 449px;
  }

  .blog-details .blog-image img{
    width: 100%;
  }

  /* .blog-details img{
    transform: translateY(-60px);
  } */

  .blog-paras{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 25px;

  }
  
  .blog-title {
    font-weight: 500;
    text-transform: uppercase;
    text-align: left;
  }
  
  .blog-post-para {
    color: #666;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    max-width: 753px;
  }
  
  .read-more {
    width: 148px;
    height: 36px;
    border-radius: 18.5px;
    border: 1px solid var(--Primary, #3e0ffe);
    background: var(--White, #fff);
    color: var(--Primary, #3e0ffe);
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; 
    cursor: pointer;
  }

  @media (max-width: 430px){
    .blog-inner {
      padding: 110px 20px 50px 20px;
    }
    
    .blog-heading {
      margin-bottom: 15px;
      font-weight: 500;
    }

    .read-more {
      width: 132px;
      height: 32px;
    }
    
  }
  