.featured-blogs-inner {
    padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 17px;
  flex-shrink: 0;
  border-radius: 20px;
  background: var(--blue-off-3, #f8f8ff);
}

.featured-blogs-input{
    max-width: 507px;
}

.featured-blogs-input input{
    padding-left: 15px;
    background: var(--White, #FFF);
}

.featured-blogs-textarea textarea{
    background: var(--White, #FFF);
    border-radius: 8px;
}