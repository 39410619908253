.global-table {
  width: 100%;
  border-collapse: collapse;
}

.global-table-head {
  background: var(--Grey-4, #f4f6f8);
}

.global-table-head-data {
  padding: 20px 10px;
  color: #000;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
}
.global-table-body-data {
  padding: 20px 10px;
}
.table-simple-text {
  color: #666;
  font-family: Roboto;
  font-size: 16.045px;
  font-weight: 400;
}
.table-link-text {
  color: var(--Primary, #3e0ffe);
  text-align: right;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
}
.global-table-body-data{
    border: 1px solid rgba(84, 89, 255, 0.10);
    text-align: center;
}
.global-table-body-data img{
  cursor: pointer;
}

@media(max-width:430px){
  .global-table{
    display: none;
  }
}