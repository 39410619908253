.about-main {
  padding-top: 70px;
  padding-left: 80px;
  padding-right: 80px;
  display: flex;
  justify-content: space-between;
  /* flex-wrap: wrap; */
}

.about-left .about-head {
  max-width: 500px;
  color: #000;
  font-family: Roboto;
  font-size: 35px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.business-timings-container,
.booking-cancellation-policy {
  padding-left: 80px;
  padding-right: 80px;
}
.business-timings-container h1,
.booking-cancellation-policy h1 {
  color: #000;
  font-family: Roboto;
  font-size: 35px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 50px;
}

.about-left .about-para {
  margin-top: 25px;
  max-width: 500px;
  color: #666;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
.about-left {
  display: flex;
  flex-direction: column;
}
.about-left textarea {
  border: none;
  height: 300px;
  resize: none;
}

.about-section-two {
  padding-left: 80px;

  margin-top: 50px;
}

.secction-two-head {
  color: #000;
  font-family: Roboto;
  font-size: 34px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* text-align: center; */
}

.section-two-main {
  padding-right: 80px;
  margin-top: 50px;
  margin-bottom: 66px;
  display: flex;
  justify-content: space-between;
  /* flex-wrap: wrap; */
}

.about-left-para {
  width: 580px;
  color: #666;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  height: 250px;
  border: none;
  resize: none;
}

.about-right {
  position: relative;
  width: 650px;
  height: 400px;
  border-radius: 20px;
}

.about-image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 20px;
}
.about-head:disabled,
.secction-two-head:disabled {
  background-color: transparent;
}
/* .section-two-right{
    position: relative;
    width: 650px;
    height: 400px;
} */

/* .section-two-right img{
  height: 100%;
  width: 100%;
} */

.booking-cancellation-policy textarea {
  width: 100%;
  min-height: 200px;
  margin-bottom: 50px;
  border: none;
  resize: none;
  color: #666;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
.about-section-main-container {
  /* max-width: 1440px;
  margin: 0 auto; */
  /* border: 1px solid red; */
}

.business-timings-container table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 30px;
}
.business-timings-container table thead tr {
  background-color: #f4f6f8;
  border: none;
}
.business-timings-container table thead tr th {
  padding: 10px;
  color: #000;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.business-timings-container table td {
  border: 1px solid var(--blue-off-1, rgba(84, 89, 255, 0.1));
  padding: 15px;
  color: #666;
  text-align: center;
  font-family: Roboto;
  font-size: 16.045px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.use-default-button-container.about{
  z-index: 1;
  top: 70px;
}
@media (max-width: 431px) {
  .about-section-two {
    padding-left: 20px;
  }

  .about-left-para {
    text-align: start;
    color: #666;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    width: 330px;
    margin-bottom: 20px;
    padding-right: 0px;
    text-align: justify;
  }

  .about-section-two {
    padding-right: 20px;
  }

  .secction-two-head {
    color: #000;
    font-family: Roboto;
    font-size: 34px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    max-width: 390px;
  }

  .about-main {
    padding-top: 80px;
    padding-left: 20px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-right: 20px;
  }

  .about-right {
    /* border: 2px solid green; */
    height: 250px;
  }

  /* .about-right img {
    width: 100%;
    height: 100%;
    border: 1px solid red;
  } */

  .section-two-right {
    max-width: 390px;
    height: 250px;
  }

  .section-two-right img {
    max-width: 100%;
    height: 100%;
  }

  .about-para {
    margin-bottom: 20px;
    width: 350px;
    padding-right: 0px;
    color: #666;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

  .about-left .about-head {
    max-width: 309px;
    /* max-width: 304px; */
    color: #000;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Roboto;
    font-size: 34px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .about-left {
    width: 100%;
  }

  .about-left .about-para {
    max-width: auto;
    width: 100%;
    text-align: justify;
  }
  .about-left textarea {
    height: 150px;
    overflow-y: auto;
  }
  .secction-two-head {
    text-align: left;
    max-width: 100%;
    font-size: 28px;
  }
  .section-two-right {
    width: 100%;
  }
  .section-two-main {
    padding-right: 0px;
    flex-wrap: wrap;
  }

  .business-timings-container,
  .booking-cancellation-policy {
    padding-left: 20px;
    padding-right: 20px;
  }
  .business-timings-container h1,
  .booking-cancellation-policy h1 {
    font-size: 24px;
    margin-bottom: 25px;
  }
  .business-timings-container table {
    width: 100%;
    margin-bottom: 30px;
  }

  .booking-cancellation-policy textarea {
    width: 100%;
    min-height: 200px;
    margin-bottom: 50px;
    border: none;
    resize: none;
    color: #666;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
}
