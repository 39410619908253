.get-consultation-btn {
  border-radius: 18.5px;
  border: 1px solid var(--Primary, #3e0ffe);
  background: var(--White, #fff);
  padding: 7px 45px;
  color: var(--Primary, #3e0ffe);
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

a.login-btn {
  color: var(--Secondary, #5459ff);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.professional-navbar-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
/* nav.border-none, div.border-none{
    border: none;
} */

.professional-navigationbar {
  padding: 17.5px 80px;
  gap: 20px;
  border-bottom: 1px solid rgba(102, 102, 102, 0.6);
  background: transparent;
  position: relative;
  width: 100%;
  z-index: 10;
}

@media(max-width:1070px){
  .professional-navbar-inner{
    display: none;
  }
}
