.invoice-details-container {
  padding: 0 30px;
  padding-left: 20px;
}

.invoice-details-top-badge {
  border-radius: 8px;
  background: var(
    --Gradient,
    linear-gradient(4deg, #3e0ffe 3.77%, #5459ff 59.6%)
  );
  padding: 12px 20px;
}
.invoice-details-top-badge span {
  color: var(--White, #fff);
  font-family: Roboto;
  font-size: 18px;
  font-weight: 400;
  line-height: 22.862px;
  letter-spacing: 0.312px;
}
.invoice-badge-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.invoice-details-heading {
  color: #000;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  line-height: 22.862px;
  letter-spacing: 0.312px;
}

.invoice-badge-container span {
  color: #666;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 400;
  line-height: 22.862px;
  letter-spacing: 0.312px;
}

.invoice-table {
  max-width: 450px;
}
.invoice-information-left table {
  width: 100%;
}

.mb-45 {
  padding-bottom: 45px;
}

.invoice-information {
  border-radius: 8px;
  border: 0.5px solid var(--Blue-off-0, rgba(84, 89, 255, 0.4));
  background: var(--blue-off-3, #f8f8ff);
  padding: 45px 30px;
  display: flex;
  margin-bottom: 30px;
}
.invoice-information span,
.invoice-details-bottom-table span {
  color: #666;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.862px;
  letter-spacing: 0.312px;
}
.invoice-information-left {
  width: 50%;
  border-right: 1px solid #666;
}
.invoice-information-right {
  width: 50%;
}

.invoice-information-right div {
  max-width: 450px;
  padding-left: 70px;
}
.invoice-information-right div table {
  width: 100%;
}
.invoice-status-badge {
  border-radius: 5px;
  background: #e4ffe4;
  padding: 5px 10px;
  color: var(--green, #30e702);
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
}
.invoice-details-bottom-table{
    margin-bottom: 30px;
}


@media (max-width: 430px){
  .invoice-details-container {
    padding: 0 20px;
    padding-left: 20px;
    padding-top: 20px;
  }
  
  .invoice-details-top-badge {
    border-radius: 0px;
    padding: 8px 10px;
  }
  .invoice-details-top-badge span {
    font-size: 12px;
  }
  .invoice-details-heading {
    font-size: 15px;
  }
  
  .invoice-badge-container span {
    font-size: 12px;
  }
  
  .invoice-table {
    max-width: 450px;
  }
  .invoice-information-left table {
    width: 100%;
  }
  
  .mb-45 {
    padding-bottom: 20px;
  }
  
  .invoice-information {
    padding: 15px 15px;
    display: flex;
    flex-wrap: wrap;
  }
  .invoice-information span,
  .invoice-details-bottom-table span {
    font-size: 14px;
  }
  .invoice-information-left {
    width: 100%;
    border-right: none;
  }
  .invoice-information-right {
    width: 100%;

  }
  
  .invoice-information-right div {
    padding-left: 0px;
    margin-top: 20px;
  }
  .invoice-information-right div table {
    width: 100%;
  }
  .invoice-details-bottom-table{
      margin-bottom: 30px;
      width: 100%;
  }

  .invoice-details-bottom-table table{
    width: 100%;
  }

  .extra-pLeft{
    padding-left: 30px;
  }
  .invoice-information td{
    width: 50%;
  }
}