.help-center-container .container {
    min-height: 70vh;
    display: flex;
    flex-direction: column;
}

.help-center-container header {
    padding: 2rem 0;
}

.help-center-container .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

.help-center-container .logo-circle {
    width: 2rem;
    height: 2rem;
    background-color: #5B45FF;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.help-center-container .logo-inner-circle {
    width: 1rem;
    height: 1rem;
    border: 2px solid white;
    border-radius: 50%;
}

.help-center-container .logo-text {
    font-size: 1.25rem;
    font-weight: 600;
}

.help-center-container main {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    text-align: center;
}

.help-center-container h1 {
    font-size: 4rem;
    color: #5B45FF;
    margin-bottom: 1rem;
}

.help-center-container .tagline {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 3rem;
    font-size: 1.25rem;
}

.help-center-container .rocket-icon {
    width: 1.5rem;
    height: 1.5rem;
}

.help-center-container .registration-form {
    max-width: 600px;
    width: 100%;
}

.help-center-container h2 {
    font-size: 1.25rem;
    margin-bottom: 1rem;
}

.help-center-container .form-row {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
}

.help-center-container input[type="text"],
.help-center-container input[type="email"] {
    flex: 1;
    padding: 0.75rem;
    border: 1px solid #5B45FF;
    border-radius: 4px;
    font-size: 1rem;
}

.help-center-container .checkbox-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    margin-bottom: 1rem;
}

.help-center-container input[type="checkbox"] {
    width: 1rem;
    height: 1rem;
}

.help-center-container button {
    background-color: #5B45FF;
    color: white;
    border: none;
    padding: 0.75rem 2rem;
    font-size: 1rem;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.help-center-container button:hover {
    background-color: #4B35FF;
}

.help-center-container footer {
    background-color: #2D3748;
    color: white;
    text-align: center;
    padding: 1rem;
}

@media (max-width: 768px) {
    .help-center-container h1 {
        font-size: 3rem;
    }

    .help-center-container .form-row {
        flex-direction: column;
    }
}
