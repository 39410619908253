.global-tag{
    background-color: #3E0FFE;
    padding: 5px 15px;
    border-radius: 30px;
}
.global-tag p{
    font-family: Roboto;
    color: #fff;
    font-size: 12px;
}


@media(max-width:430px){
    .global-tag{
        padding: 4px 9px;
        border-radius: 20px;
    }
    .global-tag p{
        font-size: 10px;
    }
}