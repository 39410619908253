.add-subscription-invice-inputs{
    display: flex;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
    max-width: 1100px;
}
.add-subscription-invice-input{
    flex: 1 1 270px;
    max-width: 290px;
}

@media(max-width:430px){
    .add-subscription-invice-input{
        max-width: 100%;
    }
}