.add-subscription-checkbox{
    margin-top: 20px;
}


.add-subscription-inputs{
    display: flex;
    align-items: center;
    max-width: 1100px;
    gap: 15px;
    flex-wrap: wrap;
}

.add-subscription-input{
    flex: 1 1 270px;
    max-width: 290px;

}

@media(max-width:430px){
    .add-subscription-input{
        max-width: 100%;
    }
}