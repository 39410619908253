a.footer-logo {
    cursor: pointer;
}

a.footer-logo img {
    width: 137px;
    height: 32px;
}

.classic-template-footer {
    padding: 8px 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 45px;
    background: var(--Dark, #25233C);
}

.classic-template-footer-left {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 45px;
}

.vertical-line {
    width: 1px;
    height: 47.7px;
    background: #FFF;
}

.social-media-links {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
}

.social-media-outer {
    width: 37px;
    height: 37px;
    border-radius: 50%;
    background: #192743;
    display: flex;
    align-items: center;
    justify-content: center;
}

.classic-template-footer-middle,
.classic-template-footer-right a {
    color: var(--White, #FFF);
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.classic-template-footer-right a {
    cursor: pointer;
}


@media(max-width:431px) {
    .classic-template-footer {
        padding: 14px 15px;

        gap: 10px;
        flex-wrap: wrap;
         background: var(--Dark, #101420);
    }

    .classic-template-footer-left {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 15px;
    }

    a.footer-logo img {
        width: 70px;
        height: 22px;
    }

    .vertical-line {
        width: 0.299px;
        height: 30.345px;
        display: none;
    }

    .social-media-links {
        display: inline-flex;
        align-items: flex-start;
        gap: 6.475px;
    }

    .social-media-outer {
        width: 15.97px;
        height: 15.97px;
        gap: 6.47px;
        /* padding-left: 16px; */
        border-radius: 50%;
        background: #192743;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .classic-template-footer-middle,
    .classic-template-footer-right a {
        color: var(--White, #FFF);
        font-family: Roboto;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .social-media-outer i {
        /* width: 15.97px;
height: 15.97px; */

        font-size: 15px;
    }
}