.add-invoice-inputs {
  display: flex;
  max-width: 1100px;
  flex-wrap: wrap;
  gap: 20px;
}

.add-invoice-input {
  flex: 1 1 270px;
  max-width: 290px;
}
.fill-previous-btn.service-add-custom-btn {
  /* position: absolute; */
  /* right: -150px;
  top: 36%; */
  top: -7px;
}
.service-input-invoice {
  /* position: relative; */
  /* border: 1px solid red; */
  position: relative;
}

.add-extra-invoice-service {
  width: 500px;
  padding: 50px;
}
.add-extra-service-member-input {
  width: 100%;
}
.invoice-total-amount {
  color: #000;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 400;
  line-height: 127.188%;
  height: 30px;
}

.view-voucher-popup{
  padding: 30px;
  padding-top: 40px;
  width: 800px;
  /* position: relative; */
}

/* .view-invoice-close{
  position: absolute;
  top: 10px;
  right: 10px;
} */
@media (max-width: 430px) {
  .add-invoice-input {
    max-width: 100%;
  }
}
