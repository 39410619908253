.add-client-refs-inputs{
    display: flex;
    flex-wrap: wrap;
    max-width: 1100px;
    gap: 20px;
}
.add-client-refs-input{
    flex: 1 1 270px;
    max-width: 290px;
}

@media(max-width:430px){
    .add-client-refs-input{
        max-width: 100%;
    }
}