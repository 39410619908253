.keyword {
  border-radius: 70px;
  background: #eee;
  padding: 5px 10px;
  color: #666;
  font-family: Roboto;
  font-size: 16.045px;
  font-weight: 400;
}


.keywords-div{
    /* max-width: 330px; */
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    flex-wrap: wrap;
    /* border: 1px solid red; */
}