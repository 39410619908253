.global-dropdown h3 {
  color: #666;
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 10px;
}
.global-dropdown {
  width: 100%;
  position: relative;
  z-index: 2;
}
.global-drop-head {
  border-radius: 8px;
  background: var(--Grey-4, #f4f6f8);
  padding: 15px;
  cursor: pointer;
}
.global-drop-head p {
  color: #666;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.global-drop-dropdown {
  position: absolute;
  border-radius: 8px;
  background: var(--White, #fff);
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.15);
  width: 100%;
  margin-top: 10px;
  overflow: auto;
}
.global-drop-dropdown div {
  padding: 15px;
  border-bottom: 0.5px solid var(--Grey-5, #cdcdcd);
}
.global-drop-dropdown div h2 {
  color: #000;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;

}
.global-drop-dropdown div h2:hover{
  color: #4d40ff;
}
.zIndex-1{
  z-index: 1;
}
.zIndex-2{
  z-index: 2;
}
.zIndex-3{
  z-index: 3;
}
.zIndex-4{
  z-index: 4;
}
.zIndex-5{
  z-index: 5;
}
.zIndex-6{
  z-index: 6;
}
.zIndex-7{
  z-index: 7;
}
.zIndex-8{
  z-index: 8;
}
.zIndex-9{
  z-index: 9;
}
.zIndex-10{
  z-index: 10;
}