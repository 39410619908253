.notification-date-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 15px;
  margin-bottom: 25px;
}

.notification-date {
  color: #666;
  text-align: right;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
}

.notifications-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 15px;
  width: 100%;
}

.notification-badge {
  background: var(--blue-off-3, #f8f8ff);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  padding: 10px;
}

.notifiaction-sender-name {
  color: var(--Secondary, #5459ff);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 600;
  max-width: 25%;
}

.notification-message {
  color: #666;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  max-width: 77%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


@media(max-width:430px){
  .edit-client-heading{
    margin-top: 90px;
  }
}