.subscriptions-main-container {
  padding: 0 30px;
  padding-left: 20px;
}

.subscription-badge {
  border-radius: 5px;
  padding: 5px 10px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
}
.subscription-badge-active {
  background: rgba(151, 71, 255, 0.1);
  color: #9747ff;
}
.subscription-badge-expired {
  background: rgba(255, 75, 64, 0.1);
  color: #ff4b40;
}


@media(max-width:430px){
  .subscriptions-main-container{
    padding-top: 30px;
  }
}