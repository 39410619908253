.appoinment-settings-container {
  padding: 0 30px;
  padding-left: 20px;
  margin-bottom: 100px;
}
.appoinment-settings-container h1 {
  color: #000;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 10px;
}
.appointment-settings-below-inputs {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}
.prevent-booking-head {
  color: var(--Dark, #25233c);
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 15px;
}

.prevent-booking-input {
  width: 350px;
  margin-bottom: 30px;
}
.appointment-settings-first-input {
  display: flex;
  align-items: center;
  gap: 15px;
}
.appointment-settings-first-input span {
  color: #666;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
}

.appointment-settings-head {
  color: #666;
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 10px;
}
.unavailable-dated-container {
  margin-top: 30px;
  display: flex;
  gap: 20px;
}
.unavailable-dated-container-right {
  width: 290px;
}

.calendar {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 5px;
  max-width: 600px;
  margin: auto;
  border-radius: 5px;
  overflow: hidden;
}
.calender-outer {
  border-radius: 9.675px;
  border: 0.806px solid var(--White, #fff);
  background: var(--blue-off-3, #f8f8ff);
  padding: 20px;
}
.calender-outer p {
  color: var(--Main-text, #000);
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 22.574px;
  margin-bottom: 15px;
}
.header {
  text-align: center;
  font-weight: bold;
  padding: 10px;
  background-color: #f0f0f0;
}

.day-of-month {
  color: var(--sub-heading, #696969);
  font-family: Poppins;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.day-of-week {
  color: var(--Main-text, #000);
  font-family: Poppins;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.day {
  /* background-color: red; */
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 10px;
  flex-direction: column;
  cursor: pointer;
  border-radius: 5px;
}
.appointment-setting-info {
  color: #000;
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 30px;
}
.appointment-settings-first-input-outer {
  display: flex;
  align-items: center;
  gap: 20px;
}
.appointments-transform {
  transform: translateY(15px);
}
.top-days {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 10px;
}
.day:hover {
  background-color: #5459ff;
}
.day:hover .day-of-month,
.day:hover .day-of-week {
  color: #fff;
}
.day.date-active {
  background-color: #5459ff;
}
.day.date-active .day-of-month {
  color: #fff;
}
.weekend {
  color: red;
}

@media (max-width: 1480px) {
  .appointment-settings-below-inputs {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
  }
}
@media (max-width: 430px) {
  .appointment-settings-below-inputs {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
  }
  .appoinment-settings-container {
    padding: 0 50px;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 100px;
    margin-top: 85px;
  }
  .unavailable-dated-container {
    flex-direction: column-reverse;
  }
  .unavailable-dated-container-right {
    width: 100%;
  }
  .appointment-settings-first-input-outer {
    flex-direction: column;
    
  }
  .prevent-booking-input {
    width: 100%;
  }
  .appointment-settings-first-input{
    width: 100%;
  }
}
