@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Kantumruy+Pro:wght@100;200;300;400;500;600;700&family=Poppins:wght@100;200;300;400;500;600;700;800&family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,100&display=swap");
.section1-inner {
  background: linear-gradient(
    270deg,
    rgba(84, 89, 255, 0.22) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  padding: 163px 90px 93px 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.section1-left {
  max-width: 610px;
}

.section1-left .main-heading {
  color: #000;
  font-family: Roboto;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  max-width: 547px;
  margin-bottom: 15px;
}

.section1-left .semi-heading {
  color: #7d7eaa;
  font-family: Roboto;
  font-size: 34px;
  font-style: normal;
  font-weight: 500;
  line-height: 137.188%;
  margin-bottom: 20px;
}

.section1-left .sec1-para {
  color: #666;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 127.188%;
  max-width: 570px;
  margin-bottom: 25px;
}

.section1-left .trail-button {
  width: 177px;
  height: 36px;
  border-radius: 18.5px;
  background: #3e0ffe;
  color: #fff;
  font-family: Roboto;
  font-size: 13px;
  font-weight: 400;
  line-height: 22px;
}

.section2-inner {
  padding: 50px 20px 70px 20px;
}

.sec2-heading {
  margin-bottom: 44px;
}

.sec2-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 25px;
}

#card2 {
  background: url("../../../../public/home/business2.png");
}
#card3 {
  background: url("../../../../public/home/business3.png");
}
#card4 {
  background: url("../../../../public/home/business4.png");
}

.sec2-card {
  background: url("../../../../public/home/business1.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  max-width: 301px;
  height: 378px;
  position: relative;
  overflow: hidden;
  flex: 1 1 250px;
}

.sec2-card:hover {
  background: none;
  border-radius: 35px 10px;
}

.sec2-card:hover .overlay {
  /* height: 0; */
  transform: translateY(-400px);
  transition: all 0.5s ease-in-out;
}

.sec2-card:hover .sec2-card-head {
  display: none;
}

.sec2-card:hover .after-hover {
  display: flex;
  /* height: 100%; */
  transform: translateY(0px);
  background-color: #fff;
  transition: all 0.5s ease-in-out;
}

.sec2-card:hover .sec2-card-btn-hover {
  display: block;
}

.overlay {
  width: 100%;
  height: 236px;
  border-radius: 0px 0px 10px 10px;
  background: linear-gradient(
    180deg,
    rgba(247, 247, 247, 0) 0.27%,
    #000 98.04%
  );
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1 1 250px;
}

.sec2-card-head {
  color: #fff;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  margin-bottom: 22px;
  width: 100%;
}

.after-hover {
  width: 100%;
  height: 100%;
  border-radius: 35px 10px;
  border: 1px solid #d8dfff;
  /* background: #fff; */
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 40px;
  align-items: center;
  position: absolute;
  padding-bottom: 41px;
  transform: translateY(400px);
}

.sec2-card-head-hover {
  color: var(--Dark, #25233c);
  font-family: Roboto;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-top: 41px;
}

.sec2-card-para-hover {
  color: var(--Grey-3, #656263);
  text-align: center;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-width: 259px;
  /* margin: 0 auto; */
}

.sec2-card-btn-hover {
  border-radius: 18.5px;
  border: 1px solid var(--Primary, #3e0ffe);
  background: var(--White, #fff);
  color: var(--Primary, #3e0ffe);
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  width: 148px;
  height: 36px;
  position: absolute;
  bottom: 30px;
  cursor: pointer;
  display: none;
}

.sec3-inner {
  padding: 81px 20px 41px 20px;
  background: #f8f8ff;
}

.sec3-heading {
  margin-bottom: 25px;
}

.sec3-para {
  color: var(--Grey-3, #656263);
  text-align: center;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 127.188%;
  margin-bottom: 81px;
}

.sec3-features-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 25px;
}

.sec3-features-cards-left {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.sec3-feature-card-large {
  width: 410px;
  height: 283px;
  border-radius: 8px;
  border: 1px solid #e3dddd;
  background: var(--White, #fff);
}

.feature-card-head {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: #eeefff;
  padding: 14px 0 15px 14px;
}

.card-icon {
  width: 45px;
  height: 44px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  fill: var(--blue-off-1, rgba(84, 89, 255, 0.1));
  background: #dfe0ff;
}

.card-heading {
  color: #000;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.card-list {
  padding: 15px 0 8px 38px;
  display: flex;
  flex-direction: column;
}

.card-list li {
  color: rgba(0, 0, 0, 0.8);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 191%; /* 30.56px */
  letter-spacing: -0.176px;
}

#feature-card1 {
  height: 174px;
}

.feature-smallcard {
  display: flex;
  justify-content: space-between;
}

.feature-smallcard {
  padding: 15px 48px 8px 38px;
}

.feature-smallcard li {
  color: rgba(0, 0, 0, 0.8);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 191%; /* 30.56px */
  letter-spacing: -0.176px;
}

.sec3-features-cards-middle {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

#feature-card2 {
  height: 184px;
}
#feature-card3 {
  height: 273px;
}

.sec4-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 45px 0px 25px 0px;
}

.sec4-heading {
  margin-bottom: 25px;
}

.sec4-para {
  max-width: 790px;
  margin-bottom: 48px;
}

.templates {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  /* justify-content: space-between; */
  justify-content: center;
  gap: 18px;
  margin-bottom: 26px;
}

.templates img:first-child,
.templates img:last-child {
  max-width: 350px;
}

.templates img:nth-child(2) {
  max-width: 380px;
}

.section5{
  background: #f8f8ff;
}

.sec5-inner {
  padding: 70px 20px 115px 20px;  
  max-width: 1340px;
  margin: 0 auto;
}

.sec5-heading {
  margin-bottom: 25px;
}

.sec5-para {
  max-width: 879px;
  margin: 0 auto;
  margin-bottom: 80px;
}

.testimonials {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 50px;
  
  /* border: 1px solid red; */
}

.testimonials .swiper{
  padding: 60px  20px;
}
.testimonials .swiper .swiper-slide-active{
  /* padding: 60px 0px; */
  /* margin-left: 20px;  */
}

.testimonial {
  max-width: 595px;
  margin: 0 auto;
  height: 320px;
  border-radius: 15px;
  border-bottom: 5px solid var(--Gradient, #3e0ffe);
  background: var(--White, #fff);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  
}

.qouts1 {
  position: absolute;
  top: 51px;
  left: 32px;
}

.error{
  color: red;
}

.textImg {
  position: absolute;
  top: -52px;
  left: 200px;
  border-radius: 105.706px;
border: 1.5px solid #3E0FFE;
width: 105px;
height: 105px;
display: flex;
align-items: center;
justify-content: center;
}
.textImg img {
  width: 100%;
  height: 100%;
  object-fit: fill;
  border-radius: 100%;
}

.pngLogo {
  position: absolute;
  top: 0;
  right: 0;
}

.testimonials-para {
  max-width: 522px;
  text-align: center;
  height: 115px;
  overflow: hidden;
  text-overflow: ellipsis;
  /* white-space: wrap; */
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  margin-top: 95px;
  margin-left: 17px;
  margin-right: 17px;
  margin-bottom: 21px;
  font-size: 18px;
  font-style: normal;
font-weight: 400;
line-height: 127.188%;
  /* border: 1px solid red; */
  display: -ms-flexbox;
  /* display: flex; */
  -ms-flex-direction: column;
  -ms-flex-direction: column;
  -ms-line-clamp: 5;
}

.qouts2 {
  position: absolute;
  right: 31px;
  bottom: 85px;
}

.test-client-name {
  color: #000;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 10px;
}

.test-loc {
  color: #666;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.sec6-inner {
  height: 482px;
  background: linear-gradient(263deg, #6ac0ff 4.24%, #af72ff 59.49%);
  display: flex;
  gap: 42px;
  align-items: center;
}

.sec6-left {
  height: 100%;
  width: 50%;
}
/* .sec6-left video{
  height: 100%;
  width: 100%;
  margin-left: 20px;
} */
.sec6-left img {
  height: 100%;
  width: 100%;
}

.video-section {
  margin-top: 50px;
  max-width: 1440px;
  margin: 0 auto;
  height: 482px;
}

.video-section video {
  width: 100%;
  height: 100%;
}

.sec6-right {
  margin-left: 42px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: flex-start;
}

.sec6-heading {
  color: var(--White, #fff);
  font-family: Roboto;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
}

.sec6-list {
  margin-left: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}

.sec6-list li {
  color: var(--Grey-4, #f4f6f8);
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.sec6-btn {
  width: 166px;
  height: 36px;
  border-radius: 18.5px;
  border: 1px solid var(--White, #fff);
  background: transparent;
  color: var(--White, #fff);
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 183.333% */
}

@media (max-width: 1320px) {
  .sec3-feature-card-large {
    width: 350px;
    height: 283px;
    border-radius: 8px;
    border: 1px solid #e3dddd;
    background: var(--White, #fff);
  }
}

@media (max-width: 1300px) {
  .section1-right {
    max-width: 500px;
  }

  .section1-right img {
    width: 110%;
  }
}

@media (min-width: 1000px) and (max-width: 1300px) {
  .section1-inner {
    background: linear-gradient(
      270deg,
      rgba(84, 89, 255, 0.22) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    padding: 163px 80px 93px 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
  }

  .section1-left {
    max-width: 610px;
  }

  .section1-left .main-heading {
    font-size: 36px;
    font-weight: 600;
    line-height: 45px;
    max-width: 547px;
  }

  .section1-left .semi-heading {
    color: #7d7eaa;
    font-family: Roboto;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: 125.188%;
    margin-bottom: 20px;
  }

  .section1-left .sec1-para {
    color: #666;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 127.188%;
    max-width: 570px;
    margin-bottom: 25px;
  }

  .section1-left .trail-button {
    width: 177px;
    height: 36px;
    border-radius: 18.5px;
    border: 1px solid var(--Primary, #3e0ffe);
    background: #fff;
    color: #3e0ffe;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    font-variant: all-small-caps;
  }

  .section2-inner {
    padding: 50px 70px 70px 70px;
  }

  .sec2-heading {
    margin-bottom: 44px;
  }

  .sec2-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
  }

  #card2 {
    background: url("../../../../public/home/business2.png");
  }
  #card3 {
    background: url("../../../../public/home/business3.png");
  }
  #card4 {
    background: url("../../../../public/home/business4.png");
  }

  .sec2-card {
    background: url("../../../../public/home/business1.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    max-width: 250px;
    height: 330px;
    position: relative;
    overflow: hidden;
    flex: 1 1 200px;
  }

  .sec2-card:hover {
    background: none;
    border-radius: 35px 10px;
  }

  .sec2-card:hover .overlay {
    /* height: 0; */
    transform: translateY(-400px);
    transition: all 0.5s ease-in-out;
  }

  .sec2-card:hover .sec2-card-head {
    display: none;
  }

  .sec2-card:hover .after-hover {
    display: flex;
    /* height: 100%; */
    transform: translateY(0px);
    background-color: #fff;
    transition: all 0.5s ease-in-out;
  }

  .sec2-card:hover .sec2-card-btn-hover {
    display: block;
  }

  .overlay {
    width: 100%;
    height: 236px;
    border-radius: 0px 0px 10px 10px;
    background: linear-gradient(
      180deg,
      rgba(247, 247, 247, 0) 0.27%,
      #000 98.04%
    );
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex: 1 1 250px;
  }

  .sec2-card-head {
    color: #fff;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    margin-bottom: 22px;
    width: 100%;
  }

  .after-hover {
    width: 100%;
    height: 100%;
    border-radius: 35px 10px;
    border: 1px solid #d8dfff;
    /* background: #fff; */
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 40px;
    align-items: center;
    position: absolute;
    padding-bottom: 41px;
    transform: translateY(400px);
  }

  .sec2-card-head-hover {
    color: var(--Dark, #25233c);
    font-family: Roboto;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-top: 15px;
    text-align: center;
  }

  .sec2-card-para-hover {
    color: var(--Grey-3, #656263);
    text-align: center;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    max-width: 259px;
    /* margin: 0 auto; */
  }

  .sec2-card-btn-hover {
    border-radius: 18.5px;
    border: 1px solid var(--Primary, #3e0ffe);
    background: var(--White, #fff);
    color: var(--Primary, #3e0ffe);
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    width: 148px;
    height: 36px;
    position: absolute;
    bottom: 30px;
    cursor: pointer;
    display: none;
  }

  .sec3-inner {
    padding: 81px 20px 41px 20px;
    background: #f8f8ff;
  }

  .sec3-heading {
    margin-bottom: 25px;
  }

  .sec3-para {
    color: var(--Grey-3, #656263);
    text-align: center;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 127.188%;
    margin-bottom: 81px;
  }

  .sec3-features-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 25px;
  }

  .sec3-features-cards-left {
    display: flex;
    flex-direction: column;
    gap: 25px;
  }

  .sec3-feature-card-large {
    width: 280px;
    height: 283px;
    border-radius: 8px;
    border: 1px solid #e3dddd;
    background: var(--White, #fff);
  }

  .feature-card-head {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background: #eeefff;
    padding: 14px 0 15px 14px;
  }

  .card-icon {
    width: 45px;
    height: 44px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    fill: var(--blue-off-1, rgba(84, 89, 255, 0.1));
    background: #dfe0ff;
  }

  .card-heading {
    color: #000;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .card-list {
    padding: 15px 0 8px 38px;
    display: flex;
    flex-direction: column;
  }

  .card-list li {
    color: rgba(0, 0, 0, 0.8);
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 191%; /* 30.56px */
    letter-spacing: -0.176px;
  }

  #feature-card1 {
    height: 174px;
  }

  .feature-smallcard {
    display: flex;
    justify-content: space-between;
  }

  .feature-smallcard {
    padding: 15px 48px 8px 38px;
  }

  .feature-smallcard li {
    color: rgba(0, 0, 0, 0.8);
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 191%; /* 30.56px */
    letter-spacing: -0.176px;
  }

  .sec3-features-cards-middle {
    display: flex;
    flex-direction: column;
    gap: 25px;
  }

  #feature-card2 {
    height: 220px;
  }
  #feature-card3 {
    height: 245px;
    transform: translateY(-8px);
  }

  .sec4-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 45px 20px 25px 20px;
  }

  .sec4-heading {
    margin-bottom: 25px;
  }

  .sec4-para {
    max-width: 790px;
    margin-bottom: 48px;
  }

  .templates {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    /* justify-content: space-between; */
    justify-content: center;
    gap: 18px;
    margin-bottom: 26px;
  }

  .templates img:first-child,
  .templates img:last-child {
    width: 330px;
  }

  .templates img:nth-child(2) {
    max-width: 400px;
    max-height: 400px;
  }

  .sec5-inner {
    padding: 70px 20px 115px 20px;
    background: #f8f8ff;
  }

  .sec5-heading {
    margin-bottom: 25px;
  }

  .sec5-para {
    max-width: 879px;
    margin: 0 auto;
    margin-bottom: 80px;
  }

  /* .testimonials {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 20px;
  } */

  .testimonial {
    max-width: 580px;
    height: 320px;
    border-radius: 15px;
    border-bottom: 5px solid var(--Gradient, #3e0ffe);
    background: var(--White, #fff);
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 1 450px;
  }

  .qouts1 {
    position: absolute;
    top: 51px;
    left: 32px;
  }

  .textImg {
    position: absolute;
    top: -52px;
    left: 200px;
  }

  .pngLogo {
    position: absolute;
    top: 0;
    right: 0;
  }

  .testimonials-para {
    max-width: 522px;
    margin-top: 92px;
    margin-left: 17px;
    margin-right: 17px;
    margin-bottom: 21px;
    font-size: 18px;
  }

  .qouts2 {
    position: absolute;
    right: 31px;
    bottom: 85px;
  }

  .test-client-name {
    color: #000;
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 10px;
  }

  .test-loc {
    color: #666;
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

  .sec6-inner {
    height: 482px;
    background: linear-gradient(263deg, #6ac0ff 4.24%, #af72ff 59.49%);
    display: flex;
    gap: 42px;
    align-items: center;
  }

  .sec6-left {
    height: 100%;
    width: 50%;
  }
  /* .sec6-left video{
    height: 100%;
    width: 100%;
    margin-left: 20px;
  } */
  .sec6-left img {
    height: 100%;
    width: 100%;
  }

  .video-section {
    margin-top: 50px;
    max-width: 1440px;
    margin: 0 auto;
    height: 482px;
  }

  .video-section video {
    width: 100%;
    height: 100%;
  }

  .sec6-right {
    margin-left: 42px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: flex-start;
  }

  .sec6-heading {
    color: var(--White, #fff);
    font-family: Roboto;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 60px;
  }

  .sec6-list {
    margin-left: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }

  .sec6-list li {
    color: var(--Grey-4, #f4f6f8);
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .sec6-btn {
    width: 166px;
    height: 36px;
    border-radius: 18.5px;
    border: 1px solid var(--White, #fff);
    background: transparent;
    color: var(--White, #fff);
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 183.333% */
  }
}

@media (min-width: 1000px) and (max-width: 1125px) {
  .templates img:first-child,
  .templates img:last-child {
    max-width: 270px;
  }

  .templates img:nth-child(2) {
    max-width: 310px;
  }
}

@media (max-width: 430px) {
  .section1-inner {
    background: linear-gradient(
      270deg,
      rgba(84, 89, 255, 0.22) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    padding: 110px 20px 50px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
  }

  .section1-left {
    max-width: 390px;
  }

  .section1-left .main-heading {
    font-size: 24px;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 10px;
  }

  .section1-left .semi-heading {
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 10px;
  }

  .section1-left .sec1-para {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 15px;
  }

  .section1-left .trail-button {
    width: 130px;
    height: 32px;
  }

  .section1-right {
    max-width: 390px;
  }

  .section1-right img {
    width: 100%;
  }

  .section2-inner {
    padding: 30px 20px;
  }

  .sec2-heading {
    margin-bottom: 25px;
  }

  /* #card2 {
    background: url("../../../../public/home/business2.png");
  }
  #card3 {
    background: url("../../../../public/home/business2.png");
  }
  #card4 {
    background: url("../../../../public/home/business3.png");
  } */

  /* .sec2-card {
    background: url("../../../../public/home/business1.png");
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 301px;
    height: 378px;
    position: relative;
    overflow: hidden;
  } */

  /* .sec2-card:hover {
    background: none;
  } */

  /* .sec2-card:hover .overlay {
    transform: translateY(-400px);
    transition: all 0.5s ease-in-out;
  } */

  /* .sec2-card:hover .sec2-card-head {
    display: none;
  } */

  /* .sec2-card:hover .after-hover {
    display: flex;
    transform: translateY(0px);
    background-color: #fff;
    transition: all 0.5s ease-in-out;
  } */

  /* .sec2-card:hover .sec2-card-btn-hover {
    display: block;
  } */

  /* .overlay {
    width: 301px;
    height: 236px;
    border-radius: 0px 0px 10px 10px;
    background: linear-gradient(
      180deg,
      rgba(247, 247, 247, 0) 0.27%,
      #000 98.04%
    );
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  } */

  .sec2-card-head {
    font-size: 16px;
  }

  /* .after-hover {
    width: 100%;
    height: 100%;
    border-radius: 35px 10px;
    border: 1px solid #d8dfff;
    background: transparent;
    display: flex;
    flex-direction: column;
    gap: 40px;
    align-items: center;
    position: absolute;
    transform: translateY(400px);
  } */

  .sec2-card-head-hover {
    font-size: 18px;
    font-weight: 400;
  }

  .sec2-card-para-hover {
    font-size: 16px;
    max-width: 250px;
    margin-top: 25px;
  }

  .sec2-card-btn-hover {
    width: 125px;
    height: 32px;
  }

  .sec3-inner {
    padding: 35px 20px 48px 20px;
  }

  .sec3-heading {
    margin-bottom: 25px;
  }

  .sec3-para {
    font-size: 16px;
    line-height: normal;
    margin-bottom: 28px;
  }

  /* .sec3-features-cards {
    display: flex;
    gap: 25px;
  } */

  /* .sec3-features-cards-left {
    display: flex;
    flex-direction: column;
    gap: 25px;
  } */

  .sec3-feature-card-large {
    width: 300px;
    height: 283px;
    border-radius: 8px;
    border: 1px solid #e3dddd;
    background: var(--White, #fff);
  }

  .card-heading {
    font-size: 14px;
  }

  .card-list {
    padding: 15px 0 8px 30px;
    display: flex;
    flex-direction: column;
  }

  .card-list li {
    color: rgba(0, 0, 0, 0.8);
    font-size: 16px;
    font-weight: 400;
  }
  #feature-card1 {
    height: 170px;
  }

  /* .feature-smallcard {
    display: flex;
    justify-content: space-between;
  } */

  .feature-smallcard {
    padding: 15px 48px 8px 30px;
  }

  .feature-smallcard li {
    font-size: 16px;
    font-weight: 400;
  }

  /* .sec3-features-cards-middle {
    display: flex;
    flex-direction: column;
    gap: 25px;
  } */

  #feature-card2 {
    height: 234px;
  }

  #feature-card3 {
    height: 240px;
  }

  .sec4-inner {
    /* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; */
    padding: 25px 20px 50px 20px;
  }

  /* .sec4-heading {
    margin-bottom: 25px;
  } */

  .sec4-para {
    /* max-width: 790px; */
    margin-bottom: 29px;
  }

  .templates {
    justify-content: center;
  }

  .templates img {
    width: 290px;
  }

  .sec5-inner {
    padding: 25px 20px 60px 20px;
  }

  .sec5-para {
    margin-bottom: 100px;
  }

  .testimonials {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }

  .testimonials .swiper{
    padding: 0px  20px;
    padding-top: 40px;
    padding-bottom: 10px;
  }

  .testimonial {
    width: 341px;
    height: 215px;
  }

  .testimonial:first-child {
    margin-bottom: 40px;
  }

  .qouts1 {
    top: 25px;
    left: 12px;
    width: 18px;
    height: 15px;
  }

  .textImg {
    top: -32px;
    left: 144px;
    width: 60px;
    height: 60px;
  }

  .pngLogo {
    display: none;
  }

  .testimonials-para {
    height: 95px;
    margin-top: 48px;
    margin-left: 12px;
    margin-right: 12px;
    margin-bottom: 12px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 15.979px;
    -webkit-line-clamp: 6;
  }

  .qouts2 {
    position: absolute;
    right: 12px;
    bottom: 35px;
    width: 18px;
    height: 15px;
  }

  .test-client-name {
    font-size: 12px;
    line-height: 12.979px;
    margin-bottom: 10px;
  }

  .test-loc {
    font-size: 12px;
    line-height: 12px;
  }

  .section6 {
    height: 630px;
  }

  .sec6-inner {
    height: 482px;
    background: linear-gradient(263deg, #6ac0ff 4.24%, #af72ff 59.49%);
    height: 296px;
    display: flex;
    justify-content: center;
    /* flex-direction: column-reverse; */
    gap: 38px;
    align-items: center;
    position: relative;
  }

  .sec6-left {
    position: absolute;
    transform: translateY(315px);
    width: 330px;
  }

  .sec6-left img {
    width: 100%;
  }

  .sec6-right {
    margin-left: 0px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: flex-start;
  }

  .sec6-heading {
    max-width: 338px;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .sec6-list {
    margin-left: 18px;
  }

  .sec6-list li {
    font-size: 20px;
  }

  .sec6-btn {
    width: 130px;
    height: 32px;
    border-radius: 18.5px;
    border: 1px solid var(--White, #fff);
    background: transparent;
    color: var(--White, #fff);
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 183.333% */
  }
}
