.integrations-heading{
    color: #000;
font-family: Roboto;
font-size: 30px;
font-style: normal;
font-weight: 700;
line-height: normal;
margin-bottom: 30px;
}

.integrations-container{
    max-width: 1000px;
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
}


.extra-right-margin:first-child, .extra-right-margin:nth-child(2){
    margin-right: 230px;
}