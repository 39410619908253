.section1 {
  padding: 136px 84px 83px 83px;
}

.about-main-head, .about-main-para, .about-contact-btn{
  display: none;
}

.about-main-img {
  max-width: 1273px;
}

.about-main-img img {
  width: 100%;
}

.sec2-inner {
  background: #f8f8ff;
  padding: 50px 163px 48px 147px;
}

.sec2-heading {
  margin-bottom: 19px;
}

.para {
  color: #666;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.sec2-para1 {
  max-width: 1130px;
  margin-bottom: 22px;
  margin: 25px auto;
  color: #666;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}
.sec2-para2 {
  max-width: 1052px;
  margin-bottom: 22px;
  color: #666;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}

@media (max-width: 430px) {
  .sec2-para1 {
    font-size: 16px;
  }
  .sec2-para2 {
    font-size: 16px;
  }
  .section1 {
    padding: 113px 20px 40px 20px;
  }

  .about-main-head, .about-main-para, .about-contact-btn{
    display: block;
  }

  .about-main-head {
    text-align: left;
    margin-bottom: 10px;
  }

  .about-main-para {
    text-align: left;
    color: #666;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .about-contact-btn {
    width: 98px;
    height: 32px;
    margin: 30px 0;
    border-radius: 25px;
    border: 1px solid var(--Primary, #3e0ffe);
    background: #fff;
    color: var(--Primary, #3e0ffe);
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; 
  }

  .about-main-img {
    max-width: 390px;
  }

  .sec2-inner {
    padding: 25px 20px;
    margin-bottom: 0px;
  }


  .sec2-heading {
    margin-bottom: 19px;
    font-weight: 500;
  }

  .para {
    font-size: 16px;
    font-weight: 400;
  }
}
