.referral-bonus{
    color: #000;
font-family: Roboto;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;
max-width: 348px;
}

@media(max-width:430px){
    .referral-bonus{
        display: none;
    }
}