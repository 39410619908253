.portal-drop-options {
  overflow-y: auto;
  transition: all 0.5s;
}

.portal-drop-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 9px 15px;
  border-radius: 8px;
  border: 1px solid var(--blue-off-1, rgba(84, 89, 255, 0.1));
  background: var(--White, #fff);
  cursor: pointer;
  text-transform: capitalize;
}
.portal-drop-head span {
  color: var(--sub-heading, #696969);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
}
.portal-dropdown {
  width: 140px;
  position: relative;
  z-index: 2;
}
.portal-drop-options {
  position: absolute;
  top: 50px;
  background: #fff;
  width: 100%;
  border-radius: 15px;
  border-radius: 5px;
  background: var(--White, #fff);
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.15);
  text-transform: capitalize;
}
.portal-drop-options .portal-drop-option {
  color: var(--sub-heading, #696969);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  padding: 9px 15px;
  cursor: pointer;
  border-bottom: 0.5px solid var(--Grey-5, #CDCDCD);
}


@media(max-width:430px){
  .portal-drop-head {
    padding: 4px 10px;

  }
  .portal-drop-head span {
    font-size: 12px;
    color: #000;
    font-weight: 400;
  }
  .portal-dropdown {
    width: 80px;
  }
  .portal-drop-chevron{
    width: 20px;
  }
}