.global-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(217, 217, 217, 0.43);
  backdrop-filter: blur(2.5px);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 100vh;
  overflow-y: scroll;
}

.gloabal-popup-inner {
  background-color: #fff;
  animation: popupAnimation 0.3s linear;
  border-radius: 10px;
  box-shadow: 0px 0px 34px 10px rgba(0, 0, 0, 0.1);
}

@keyframes popupAnimation {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

@media(max-width: 1440px){
  
  .gloabal-popup-inner {
    background-color: #fff;
    animation: popupAnimation 0.3s linear;
    border-radius: 10px;
    box-shadow: 0px 0px 34px 10px rgba(0, 0, 0, 0.1);
  }
}
