.contact-inner {
    border-radius: 30px 0px 0px 30px;
    /* background: url("/public//home/contactImg.png"); */
    display: flex;
    justify-content: space-between;
    overflow: hidden;
  }

  .contact-left{
    max-width: 664px;
    z-index: -1;
    transform: scale(1) translateX(0px) translateY(-50px);
  }

  .contact-left img{
    height: 105%;
  }

  .contact-form {
    padding: 100px 160px 0px 61px;
    border-radius: 30px 0px 0px 30px;
    background: var(--blue-off-3, #f8f8ff);
    z-index: 2;
    max-width: 776px;
  }
  
  .contact-form .logo {
    width: 188.5px;
    height: 44.8px;
    margin: 0 auto;
    margin-bottom: 72px;
  }
  
  .getInTouch {
    color: #000;
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 60px;
  }
  
  .contact-form form {
    max-width: 557px;
    display: flex;
    justify-content: space-between;
    gap: 23px;
    flex-wrap: wrap;
    position: relative;
  }
  
  .label-input {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  
  .label-input label {
    color: #666;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  
  .icon-input {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 8px;
    background: var(--White, #fff);
    max-width: 291px;
    height: 48px;
    padding-left: 15px;
    padding-right: 15px;
    gap: 20.4px;
  }
  
  .icon-input input {
    border: none;
    outline: none;
    color: #666;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  
  .icon-textarea {
    padding-left: 15px;
    padding-top: 13px;
    display: flex;
    align-items: flex-start;
    gap: 20px;
    border-radius: 8px;
    background: var(--White, #fff);
    width: 100%;
    height: 84px;
    margin-bottom: 118px;
  }
  
  .icon-textarea textarea {
    border: none;
    outline: none;
    color: #666;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    resize: none;
    padding-right: 15px;
    width: 100%;
  }
  
  .icon-textarea textarea::-webkit-scrollbar {
      width: 8px; /* width of the scrollbar */
    }
    
    .icon-textarea textarea::-webkit-scrollbar-thumb {
      background-color: #9e9c9c; /* color of the thumb */
      border-radius: 6px; /* roundness of the thumb */
    }
    
    .icon-textarea textarea::-webkit-scrollbar-track {
      background-color: #f1f1f1; /* color of the track */
    }
    
    /* Optional: Handle when the scrollbar is hovered */
    .icon-textarea textarea::-webkit-scrollbar-thumb:hover {
      background-color: #555;
    }
  
  button.contactus-btn {
    border: none;
    position: absolute;
    bottom: 45px;
    border-radius: 18.5px;
    border: 1px solid var(--Primary, #3e0ffe);
    background: var(--White, #fff);
    padding: 7px 45px;
    color: var(--Primary, #3e0ffe);
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    cursor: pointer;
  }
  

  @media (max-width: 430px){
    .contact-inner {
      border-radius: 0px 0px 0px 0px;
      /* background: url("/public//home/contactImg.png"); */
      display: flex;
      justify-content: space-between;
      overflow: hidden;
    }
  
    .contact-left{
      max-width: 664px;
      z-index: -1;
      transform: scale(1) translateX(0px) translateY(-50px);
    }
  
    .contact-left img{
      height: 105%;
      display: none;
    }
  
    .contact-form {
      padding: 90px 20px 0px 20px;
      border-radius: 0px 0px 0px 0px;
      margin: 0 auto;
    }
    
    .contact-form .logo {
      margin-bottom: 38px;
    }
    
    .getInTouch {
      color: #000;
      font-family: Roboto;
      font-size: 22px;
    }
    
    .label-input {
      width: 100%;
    }
    
    .label-input label {
      font-size: 16px;
    }
    
    .icon-input {
      max-width: 527px;
    }
    
    .icon-input input {
      font-size: 14px;
      width: 100%;
    }
    .icon-textarea textarea {
      font-size: 14px;
      width: 100%;
    }
    
    .icon-textarea textarea::-webkit-scrollbar {
        width: 4px; /* width of the scrollbar */
      }
    
    button.contactus-btn {
      padding: 5px 20px;
    }
    
  
    
  }