.steps-container {
  padding-top: 150px;
  padding-right: 100px;
  padding-left: 100px;
}

.steps-main-headings {
  margin-bottom: 80px;
}

.steps-container-steps {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  margin-bottom: 50px;
}
.steps-container-step {
  max-width: 362px;
  flex: 1 1 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 27px;
}
.circle {
  height: 146px;
  width: 146px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--blue-off-3, #f8f8ff);
  border: 1px solid rgba(84, 89, 255, 0.4);
}
.circle span {
  color: var(--Primary, #3e0ffe);
  font-family: Roboto;
  font-size: 34px;
  font-weight: 400;
  line-height: 22px;
}
.steps-container-step h2,
.steps-faq-head p {
  color: #000;
  font-family: Roboto;
  font-size: 20px;
  font-weight: 600;
}
.steps-faqs {
  background: var(--blue-off-3, #f8f8ff);
}
.steps-faq {
  border-radius: 6px;
  background: var(--White, #fff);
  margin-bottom: 20px;
  overflow: hidden;
}
.steps-faq-head {
  padding: 20px 35px;
  cursor: pointer;
}

.steps-faqs-inner {
  padding: 80px;
  padding-top: 40px;
}
.faq-head {
  color: var(--blue-off-4, #7d7eaa);
  font-family: Roboto;
  font-size: 34px;
  font-weight: 400;
  line-height: 60px;
  letter-spacing: -0.98px;
  margin-bottom: 20px;
}
.steps-faq-content p {
  padding-bottom: 50px;
  padding-top: 20px;
  
  
}
.steps-faq-content{
  transition: all .5s ease;
  padding-left: 35px;
  padding-right: 35px;
}
.steps-faq-content a{
  color: blue;
}
.steps-faq-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.step-chevron {
  height: 40px;
  width: 40px;
  border-radius: 4px;
  border: 1px solid var(--blue-off-1, rgba(84, 89, 255, 0.1));
  display: flex;
  align-items: center;
  justify-content: center;
}

.faq-email{
  color: blue;
}

@media (max-width: 430px){
  .steps-container {
    padding-top: 110px;
    padding-right: 20px;
    padding-left: 20px;
  }

  .steps-main-headings {
    margin-bottom: 35px;
  }
  
  .steps-faqs-inner {
    padding: 20px;
    padding-top: 40px;
  }
  .faq-head {
    color: var(--blue-off-4, #7d7eaa);
    font-family: Roboto;
    font-size: 34px;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 20px;
  }
  
}