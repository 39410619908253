.Professional-Services {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Roboto;
  width: 100%;
  margin-top: 40px;
}

.Professional-Services h2 {
  color: #000;
  font-family: Roboto;
  font-size: 2.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 5rem 0;
}

.Professional-Services h6 {
  display: none;
}
/* .Professional-Services p {
  display: none;
} */

.Pro-Services {
  max-width: 1340px;
  margin: 0 auto;
  display: flex;
  gap: 1.88rem;
  border-radius: 0.5rem;
  background: #fff;
  margin: auto;
  margin-bottom: 5rem;
  align-items: center;
  justify-content: center;
}
.pro-services-inner {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 1.88rem;
}
.Professional-card {
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);
  max-width: 23.4375rem;
  height: 9.125rem;
  padding: 1rem;
  display: flex;
  flex-shrink: 0;
  gap: 1.1rem;
  flex: 1 1 20rem;
}
/* .pro-services-inner .Professional-card:last-child {
  max-width: 23.4375rem;
} */
.Professional-card img {
  width: 109px;
  height: 109px;
  border-radius: 50%;
}

.Professional-card .Hair-style {
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
}
.Professional-card .Hair-style p{
  font-size: 14px;
}
.Professional-card .Hair-style h3 {
  color: #000;
  font-family: Roboto;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.Professional-card .Hair-style h5 {
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 0.9375rem; /* 93.75% */
}

.Professional-card .Hair-style button {
  width: 6.5rem;
  height: 2rem;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 1.5625rem;
  border: 1px solid #3e0ffe;
  background: #fff;
  color: var(--Primary, #3e0ffe);
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem;
  margin-top: 8px;
}

@media (max-width: 430px) {
  .Professional-Services {
    display: flex;
    align-items: flex-start;
    padding: 0 20px;
  }
  .Professional-Services h2 {
    padding: 5rem 0 2rem 0;
    margin: auto;
  }

  .Professional-Services h6 {
    display: block;
    color: #000;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 7px 17px;
  }
  /* .Professional-Services p {
    display: block;
    color: #666;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 7px 17px;
  } */

  .Pro-Services {
    margin: 0px 0 50px 0px;
  }

  .Professional-card {
    max-width: 350px;
  }
}
