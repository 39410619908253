.client-details-container {
  padding: 0 20px;
  padding-right: 30px;
}

.client-information,
.client-information-second {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px;
  background: var(--blue-off-3, #f8f8ff);
  padding: 15px;
  border-radius: 5px;
  /* justify-content: space-between; */
  /* max-width: 1240px; */
}
.badge-voucher{
  width: 170px;
  border: 1px solid red;
}
.client-information-second {
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.client-details-badge {
  flex: 1 1 100px;
}
.client-details-badge h3 {
  color: #666;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 10px;
}
.client-details-badge h2 {
  color: #000;
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
}

@media (max-width: 430px) {
  .client-details-container {
    padding: 0 15px;
  }
  .badge-voucher{
    width: 150px;
    border: 1px solid red;
  }
}

@media(max-width:390px){
  .badge-voucher{
    width: 140px;
    border: 1px solid red;
    /* padding-left: 10px; */
  }
}
