.add-testimonial-popup{
    width: 500px;
    padding: 20px;
}
.add-testimonial-popup h1{
    font-family: Roboto, 'sans-serif';
    font-size: 28px;
    margin-bottom: 30px;
    color: #303030;
}
.testimonial-review{
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.edit-testimonial-actions{
    max-width: 100px;
}