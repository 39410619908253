.forget-pass-request-popup {
  width: 500px;
  padding: 30px;
}
.forget-pass-request-popup h1 {
  color: #333;
  text-align: center;
  font-family: Roboto;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 15px;
}
.forget-pass-request-popup h3 {
  color: #333;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 30px;
}
.forget-pass-request-inputs{
    margin-bottom: 50px;
}
.forget-pass-request-inputs .forget-pass-inputs-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}
.forget-pass-request-inputs input {
  width: 50px;
  height: 50px;
  border-radius: 10px;
  background: var(--White, #fff);
  box-shadow: 0px 0px 4px 3px rgba(0, 0, 0, 0.07);
  color: #000;
  text-align: center;
  font-family: Roboto;
  font-size: 20px;
  font-weight: 400;
}

.forget-pass-request-inputs input::-webkit-outer-spin-button,
.forget-pass-request-inputs input::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  margin: 0;
  -moz-appearance: textfield;
}

.updated-forget-pass-inputs{
    margin-bottom: 30px;
    width: 100%;
}

.updated-forget-pass-input{
    width: 100%;
    
}

.signup-login-right-logo{
  margin-bottom: 40px;
  position: relative;
}

.forget-pass-request-button{
  display: flex;
  justify-content: center;
}
.back.forget-back{
  position: absolute;
  top: 25%;
  left: 0;
}
@media(max-width:430px){
  .forget-pass-request-popup {
    width: 100%;
    margin-left: 15px;
    margin-right: 15px;
    padding: 20px;
  }
  .updated-forget-pass-inputs{
    width: 100%;
    margin-bottom: 0;
}
.forget-pass-request-popup h1 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
}
.forget-pass-request-popup h3 {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 30px;
}
.forget-pass-request-inputs{
    margin-bottom: 0px;
}
.forget-pass-request-inputs .forget-pass-inputs-inner {
  
  gap: 15px;
  margin-bottom: 30px;
}
.forget-pass-request-inputs input {
  width: 30px;
  height: 30px;
  border-radius: 10px;
  background: var(--White, #fff);
  box-shadow: 0px 0px 4px 3px rgba(0, 0, 0, 0.07);
  color: #000;
  text-align: center;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
}


.updated-forget-pass-input{
    width: 100%;
    
}

}