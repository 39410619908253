.outer{
  padding-left: 20px;
  margin-right: 30px;
}

.back {
  display: flex;
  gap: 10px;
  align-items: center;
  max-width: 80px;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #5459ff;
}

.edit-client-heading {
  color: #000;
  font-family: Roboto;
  font-size: 30px;
  font-weight: 400;
  margin-top: 20px;
  margin-bottom: 30px;
}

.edit-client-lables-inputs {
  max-width: 775px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: 25px;
}

.lable-input-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}

.lable-input-group label {
  color: #666;
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
}

.edit-client-icon-input {
  max-width: 240px;
  height: 48px;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  gap: 20px;
  border-radius: 8px;
  background: var(--Grey-4, #f4f6f8);
  padding: 0 15px;
  position: relative;
  margin-bottom: 15px;
}

.edit-client-icon-input input {
  background: transparent;
  outline: none;
  border: none;
  color: #666;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  width: 100%;
}

.edit-client-icon-input select{
  width: 100%;
}

.number-div-extra-width{
  max-width: 390px;
}

.edit-client-btns {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  margin-bottom: 30px;
}

.edit-client-save {
  width: 149px;
  height: 36px;
  border-radius: 8px;
  background: var(--Primary, #3e0ffe);
  color: var(--White, #fff);
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
  border: none;
  cursor: pointer;
}

.edit-client-cancel {
  width: 149px;
  height: 36px;
  border-radius: 8px;
  border: 1px solid var(--Primary, #3e0ffe);
  background: var(--White, #fff);
  color: var(--Primary, #3e0ffe);
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
  cursor: pointer;
}

@media(max-width:430px){
  .back {
    margin-top: 100px;
  }
  .outer{
    padding: 0 15px;
    margin-right: 0;
  }
  /* .edit-client-heading{
    margin-top: 90px;
  } */
}
