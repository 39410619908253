/* Consultation.css */

@import url('https://fonts.googleapis.com/css2?family=Libre+Franklin:ital,wght@0,200;1,300&family=Poppins:wght@300&family=Roboto:ital,wght@0,300;0,400;1,300&display=swap');

.consultation {
    text-align: center;
}

.consult-head {
    /* border: 1px solid red; */
    padding-top: 83px;
    color: #000;
    font-family: Roboto;
    font-size: 34px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
    margin-bottom: 40px;
}

.consult-para {
    text-align: center;
    color: #666;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 137.5% */
}

.lower-form {
    display: flex;
    justify-content: center;
}

.consultation-form-inputs{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    column-gap: 20px;
}

#nail-appointment {
    margin-left: 130px;
    margin-top: 55px;
    margin-bottom: 30px;
    width: 100%;
}

@media (max-width: 1000px){
    .consultation-form-inputs{
        display: grid;
        grid-template-columns: repeat(3,1fr);
        column-gap: 20px;
    }
    .consultation-form-inputs label{
        font-size: 18px;
    }
}
@media (max-width: 760px){
    .consultation-form-inputs{
        display: grid;
        grid-template-columns: repeat(2,1fr);
        column-gap: 20px;
    }
}

@media (max-width: 431px) {
    .consultation {
        text-align: center;
    }

    .consultation-form-inputs label{
        font-size: 16px;
    }
    
    .consult-head {
        /* border: 1px solid red; */
        padding-top: 63px;
        color: #000;
        font-family: Roboto;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-align: center;
        margin-bottom: 40px;
    }
    
    .consult-para {
        text-align: center;
        color: #666;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 400;
        line-height: normal;
        /* 137.5% */
    }
    
    .lower-form {
        display: flex;
        justify-content: center;
    }
    .consultation-form-inputs{
        display: grid;
        grid-template-columns: repeat(1,1fr);
        row-gap: 10px;
    }
    #nail-appointment {
        margin-left: 20px;
        margin-top: 55px;
        margin-bottom: 30px;
        width: 100%;
    }
}