.toggle-btn {
  display: flex;
  gap: 14px;
  align-items: center;
}

.toggle-btn .toggle-label {
    color: var(--Primary_font, #2C3643);
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
}
.toggle {
  width: 48px;
  position: relative;
}
.toggle input[type="checkbox"] {
  width: 48px;
  height: 24px;
  appearance: none;
  -webkit-appearance: none;
}

.toggle input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  height: 24px;
  width: 48px;
  border-radius: 15px;
  /* background: #d9d9d9; */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  outline: none;
  border: 1px solid #2C3643;
  cursor: pointer;
}

.toggle input[type="checkbox"]:checked{
  border: 1px solid #5459FF;
}

.toggle input[type="checkbox"]::before {
  content: "";
  height: 18px;
  width: 18px;
  border-radius: 50%;
  left: 0;
  position: absolute;
  left: 4px;
  bottom: 3.1px;
  background: #2C3643;
  display: flex;
  align-items: center;
  justify-content: center;
}
.toggle input[type="checkbox"]::after {
  content: "";
  height: 18px;
  width: 18px;
  border-radius: 50%;
  right: 0;
  position: absolute;
  right: 4px;
  bottom: 3.1px;
  background: #5459ff;
  display: none;
  align-items: center;
  justify-content: center;
}

.toggle input[type="checkbox"]:checked::after {
  display: flex;
}

.toggle input[type="checkbox"]:checked::before {
  display: none;
}

.toggle-btn-reverse{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  gap: 50px;
}