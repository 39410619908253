.global-tabs-container {
  border-bottom: 0.5px solid #666;
  padding-bottom: 10px;
  padding-right: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  z-index: 1;
}
.global-tabs-option {
  width: 100%;
  cursor: pointer;
}
.global-tabs-option h3 {
  text-align: center;
  color: #666;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
}

.global-tabs-option.global-tabs-option-active h3 {
  color: #3e0ffe;
  font-weight: 500;
}
.global-tabs-option.global-tabs-option-active {
  position: relative;
}
.global-tabs-option.global-tabs-option-active::after {
  content: "";
  height: 3px;
  width: 100%;
  background-color: #3e0ffe;
  position: absolute;
  top: 28px;
}

@media (max-width: 430px) {
  .global-tabs-option h3 {
    font-size: 12px;
  }
  .global-tabs-container {
    padding-right: 0px;
  }
  .global-tabs-option.global-tabs-option-active::after {
    height: 2px;
    width: 100%;
    position: absolute;
    top: 24px;
  }
}
