/* .page-loader {
  animation: rotate 1s infinite;
  height: 50px;
  width: 50px;
}

.page-loader:before,
.page-loader:after {
  border-radius: 50%;
  content: "";
  display: block;
  height: 20px;
  width: 20px;
}
.page-loader:before {
  animation: ball1 1s infinite;
  background-color: #000;
  box-shadow: 30px 0 0 #ff3d00;
  margin-bottom: 10px;
}
.page-loader:after {
  animation: ball2 1s infinite;
  background-color: #6350FE;
  box-shadow: 30px 0 0 #000;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg) scale(0.8);
  }
  50% {
    transform: rotate(360deg) scale(1.2);
  }
  100% {
    transform: rotate(720deg) scale(0.8);
  }
}

@keyframes ball1 {
  0% {
    box-shadow: 30px 0 0 #6350FE;
  }
  50% {
    box-shadow: 0 0 0 #6350FE;
    margin-bottom: 0;
    transform: translate(15px, 15px);
  }
  100% {
    box-shadow: 30px 0 0 #6350FE;
    margin-bottom: 10px;
  }
}

@keyframes ball2 {
  0% {
    box-shadow: 30px 0 0 #000;
  }
  50% {
    box-shadow: 0 0 0 #000;
    margin-top: -20px;
    transform: translate(15px, 15px);
  }
  100% {
    box-shadow: 30px 0 0 #000;
    margin-top: 0;
  }
} */

.page-loader {
  width: 8px;
  height: 40px;
  border-radius: 4px;
  display: block;
  margin: 20px auto;
  position: relative;
  background: currentColor;
  color: #1F1B4C;
  box-sizing: border-box;
  animation: animloader 0.3s 0.3s linear infinite alternate;
}

.page-loader::after,.page-loader::before {
  content: '';
  width: 8px;
  height: 40px;
  border-radius: 4px;
  background: currentColor;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;
  box-sizing: border-box;
  animation: animloader 0.5s  0.45s  linear infinite alternate;
}
.page-loader::before {
  left: -20px;
  animation-delay: 0s;
}

@keyframes animloader {
  0%   { height: 48px} 
  100% { height: 4px}
}