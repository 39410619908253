.access-level-team-member-drop {
  max-width: 329px;
}

.select-access-levels input[type="radio"] {
  height: 18px;
  width: 18px;
  accent-color: #6865df;
  cursor: pointer;
}

.select-access-levels label {
  cursor: pointer;
}
.select-access-levels input[type="checkbox"] {
  height: 18px;
  width: 18px;
  accent-color: #6865df;
  cursor: pointer;
}

.access-levels-checkbox {
  width: 400px;
}
.assign-access-levels-table {
  width: 100%;
  border-collapse: collapse;
}

.assign-access-levels-table thead {
  border-radius: 8px 0px 0px 0px;
  border: 1px solid var(--blue-off-1, rgba(84, 89, 255, 0.1));
  background: var(--Grey-4, #f4f6f8);
}
.assign-access-levels-table thead th {
  padding: 10px;
  color: #000;

  text-align: left;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.assign-access-levels-table td {
  border: 1px solid var(--blue-off-1, rgba(84, 89, 255, 0.1));
  padding: 10px;
  color: var(--Dark, #25233c);
  text-align: left;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
