.navbar {
  padding: 17.5px 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  border-bottom: 1px solid rgba(102, 102, 102, 0.6);
  background: #fff;
  position: relative;
  width: 100%;
  z-index: 10;
}

.nav-links {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 37px;
}

.navbar a,
.nav-links a {
  color: #666;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.navbar a.active,
.nav-links a.active {
  color: #3e0ffe;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.navbar a li {
  list-style: none;
}

.contact-login-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;
}

.contact-login-btn a {
  font-size: 18px;
}

.contact-login-btn .contact {
  width: 149px;
  height: 36px;
  border-radius: 18.5px;
  background: #3e0ffe;
  color: #fff;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  border: none;
  cursor: pointer;
}

.navbar2{
  display: none;
}

@media (max-width: 1070px) {
  .navbar {
    display: none;
  }

  .navbar2{
    display: flex;
    
  }

  .navbar2-inner {
    padding: 17.5px 20px;
    display: flex;
    flex-direction: column;
    align-content: space-between;
    justify-content: center;
    /* gap: 20px; */
    border-bottom: 1px solid rgba(102, 102, 102, 0.6);
    background: #fff;
    position: fixed;
    width: 100%;
    z-index: 2000;
  }
  .navbar2-inner-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
  }

  .header-logo img{
    /* width: 109px; */
    height: 40px;
  }

  .navbar2-right img {
    cursor: pointer;
  }

  /* .close-nav{
      display: none;
    } */

  .navbar2-inner-bottom {
    position: relative;
    max-height: 0px;
    overflow: hidden;
    transition: all 0.5s ease;
    display: flex;
    flex-direction: column;
  }

  .navbar2-inner-bottom .break-line {
    position: absolute;
    width: 100%;
    left: 0;
    top: 67%;
    background-color: #d5d2d2;
    height: 1px;
  }

  .navbar2-inner-bottom .nav-links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 30px;
    margin-bottom: 50px;
    gap: 25px;
  }

  .contact-login-btn {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    gap: 25px;
    margin-bottom: 30px;
  }

  .contact-login-btn a {
    color: #666;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .contact-login-btn .contact {
    width: 98px;
    height: 32px;
    border-radius: 25px;
  }
}
