.add-business-refferal-inputs{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    max-width: 1100px;
    margin-bottom: 30px;
}
.add-business-refferal-input{
    flex: 1 1 270px;
    max-width: 290px;
}