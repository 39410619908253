.add-blog-form-top{
    display: flex;
    justify-content: space-between;
    gap: 15px;
    flex-wrap: wrap;
}

.add-blog-form-top-inputs{
    max-height: 248px;
}

.add-blog-top-right {
  padding: 20px 17px;
  background: #f5f5f5;
  /* box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15); */
  width: 302px;
  height: 325px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  /* position: absolute;
  top: 150px;
  right: 30px; */
}

.add-blog-heading {
  cursor: pointer;
  border-radius: 8px;
  background: var(--White, #fff);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15);
  padding: 8px 19px 8px 19.999px;
  color: #17191c;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.add-blog-top-right-dropdown {
  cursor: pointer;
  border-radius: 8px;
  background: var(--White, #fff);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15);
  color: #17191c;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.add-blog-heading:hover,
.add-blog-top-right-dropdown-head:hover,
.add-blog-top-dropdown-item:hover {
  color: #31a0fa;
  background: #fafafa;
}

.add-blog-top-right-dropdown-head {
  display: flex;
  justify-content: space-between;
  padding: 8px 19px 8px 19.999px;
  background: #fafafa;
  border-radius: 8px;
}

.add-blog-top-dropdown-items {
  display: flex;
  flex-direction: column;
  color: #666;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
}

.add-blog-top-dropdown-item {
  border-bottom: 0.5px solid #dbdbdb;
  padding: 10px 19px 10px 19.999px;
}

.add-blog-top-dropdown-item:last-child {
  border-bottom: none;
}
