.subscription-invoices-container{
    padding: 0 30px;
    padding-left: 20px;
}


@media(max-width:430px){
    .subscription-invoices-container{
        padding-top: 30px;
    }
}