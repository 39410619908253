.standard-booking-sec1-overlay {
  background: rgba(211, 211, 211, 0.25);
  position: absolute;
  /* top: 150px; */
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  /* border: 1px solid; */

}

.standard-booking-sec1-overlay-inner {
  max-width: 1100px;
  margin: 0 auto;
  padding: 30px 20px;
  /* padding-top: 100px; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  z-index: 100;
}

.standard-booking-heading,
.std-booking-terms-conditions,
.std-booking-para1 {
  color: var(--White, #fff);
  font-family: Roboto;
  font-size: 24px;
  font-weight: 500;
}

.standard-booking-heading {
  margin-bottom: 27px;
}

.std-booking-terms-conditions {
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 5px;
}

.std-booking-para1 {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 20px;
}

.standard-book-form label,
.standard-book-form .first-name label {
  color: var(--White, #fff);
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.standard-template-top{
  max-width: 1050px;
  margin: 10px auto;
  z-index: 1000;
}
.standard-template-top .inner{
  width: 100%;
}
@media (max-width: 431px) {
  .standard-booking-sec1-overlay {
    z-index: -1;
  
  }
  .standard-booking-sec1-overlay-inner {
    max-width: 430px;
    margin: 0 auto;
    padding: 0px 20px;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  .standard-booking-heading,
  .std-booking-terms-conditions,
  .std-booking-para1 {
    color: var(--White, #fff);
    font-family: Roboto;
    font-size: 24px;
    font-weight: 500;
  }

  .standard-booking-heading {
    margin-bottom: 20px;
    text-align: center;
    width: 100%;
  }

  .std-booking-terms-conditions {
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 5px;
  }

  .std-booking-para1 {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 20px;
  }

  .standard-booking-sec1-overlay-inner form {
    width: 100%;
  }

  .standard-book-form label,
  .standard-book-form .first-name label {
    color: var(--White, #fff);
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}
