.contact-inner {
  margin-top: 100px;
}
.contact-left {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
  position: relative;
  z-index: 1;
}

.contact-left form{
  position: absolute;
  z-index: 2000;
  top: -10px;
  left: 0;
}
.contact-left img {
  width: 578px;
  height: 546px;
  z-index: 1;
}
.website-contact {
  background: #fcfcfc;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 40px;
  padding-top: 100px;
}

.getInTouch {
  color: #000;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto;
  font-size: 34px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 20px;
}
.contact-para {
  color: #000;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 40px;
}
.website-contact-inputs {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-bottom: 20px;
}
.website-contact form {
  width: 100%;
}

.website-contact-inputs .label-input {
  width: 100%;
}
.website-contact-inputs .label-input .icon-input {
  width: 100%;
}

.website-contact .icon-textarea {
  margin-bottom: 40px;
}
.website-contact-btn {
  color: var(--Grey-3, #656263);
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  width: 128px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border-radius: 18.5px;
  background: var(--White, #fff);
}

@media(max-width: 431px){
  .contact-inner {
    margin-top: 100px;
    /* border: 1px solid red; */
  }
  .contact-left {
    display: none;
  }
  .website-contact {
    padding: 20px;
    padding-top: 0px;
  }
  
  .website-contact-inputs {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
    margin-bottom: 20px;
  }
  
}