.access-level-team-member-drop{
    position: relative;
}

.access-level-team-member-drop .fill-previous-btn{
    top: -5px;
}
.view-all-permissions-popup{
    width: 600px;
    padding: 20px;
}
.view-all-permissions-popup h3{
    font-family: Roboto;
    margin-bottom: 30px;
}

.view-all-permissions-popup p{
    font-size: 17px;
    font-family: Roboto;
}
.view-all-permissions-popup ul{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 15px;
    margin-bottom: 20px;
}